import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useApplication } from "../../store/AppContext";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function DeleteApiKeyActionButton({ row }) {
  const { deleteApiKey, apiKeysLoading } = useApplication();
  const [deleteId, setDeleteId] = useState(null);
  return (
    <>
      <Tooltip title="Delete Api Key">
        <IconButton
          disabled={apiKeysLoading}
          onClick={() => setDeleteId(row._id)}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId(null)}
        onConfirm={async () => {
          await deleteApiKey(deleteId);
          setDeleteId(null);
        }}
      />
    </>
  );
}

import { Chip } from "@mui/material";
import AppMenuActions from "./AppMenuActions";

export const APPLICATIONS_TABLE_COLUMNS = [
  { name: "_id", label: "ID", disabledSorting: true },
  { name: "name", label: "Application Name" },
  { name: "title", label: "Application Title" },
  {
    name: "status",
    label: "Status",
    renderComponent: ({ value }) => <Chip label={value} />,
  },
  {
    name: "providers",
    label: "Providers",
    renderComponent: ({ row }) => row?.configuration?.providers?.length || 0,
  },
  {
    name: "createdAt",
    label: "Created At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "updatedAt",
    label: "Updated At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: AppMenuActions,
  },
];

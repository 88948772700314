import { Payment, Wifi } from "@mui/icons-material";
import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaypalCheckout from "../components/PaypalCheckout";
import StripeCheckout from "../components/StripeCheckout";
import Loader from "../components/ui/Loader";
import Title from "../components/ui/Title";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import ValidationService from "../services/ValidationService";
import { useApplication } from "../store/AppContext";
import { socket } from "../sockets";
import Message from "../components/ui/Message";

const SOCKET_SERVER_EVENTS = {
  CONNECTION: "connection",
  DISCONNECT: "disconnect",
  DISCONNECTING: "disconnecting",
  JOIN: "JOIN",
};

const SOCKET_CLIENT_EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  JOIN_COMPLETED: "join_completed",
  JOIN_ERROR: "join_error",
  ERROR: "error",
};

export default function Checkout() {
  const { key } = useParams();
  const { derivePublicCheckoutMetaData } = useApplication();
  const [checkoutInitialized, setCheckoutInitialized] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [hasJoined, setHasJoined] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(SOCKET_SERVER_EVENTS.JOIN, { key });
      setError(null);
    }

    function onDisconnect() {
      setIsConnected(false);
      setHasJoined(false);
    }
    derivePublicCheckoutMetaData(key)
      .then((res) => {
        setCheckoutData(res);
        socket.connect();
        socket.on("connect", onConnect);
        setCheckoutInitialized(true);
        socket.on(SOCKET_CLIENT_EVENTS.JOIN_COMPLETED, () => {
          setError(null);
          setHasJoined(true);
        });
        socket.on(
          SOCKET_CLIENT_EVENTS.JOIN_ERROR,
          ({ error = "Something went wrong" } = {}) => {
            setError(error);
            setHasJoined(false);
          }
        );
        socket.on("connect_error", (err) => {
          setError("Connection seems to be down at the moment");
          console.log(`connect_error due to ${err.message}`);
        });
        socket.on("disconnect", onDisconnect);
      })
      .catch((error) => {
        navigate("/404");
      });

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checkoutInitialized || !checkoutData) {
    return <Loader loading={!checkoutInitialized || !checkoutData} />;
  }

  const getAmount = () => {
    if (!checkoutData) return null;
    return `${checkoutData?.amount} ${checkoutData?.currency}`;
  };

  const renderProvider = () => {
    if (error) {
      return <Message severity="error" message={error} />;
    }
    if (!isConnected || !hasJoined) {
      return null;
    }
    const provider = {
      public_key: checkoutData?.public_key,
    };
    const metaData = ValidationService.isObject(checkoutData?.metaData)
      ? {
          ...checkoutData.metaData,
          public_key: checkoutData?.public_key,
          success_url:
            checkoutData?.public_checkout_success_redirect_url ||
            `/checkout-success`,
          error_url:
            checkoutData?.public_checkout_error_redirect_url ||
            `/checkout-error`,
          cacheKey: key,
        }
      : null;

    if (!metaData) {
      return null;
    }
    switch (checkoutData?.provider) {
      case "PAYPAL":
        return (
          <PaypalCheckout
            {...checkoutData}
            isGlobalCheckout={true}
            metaData={metaData}
            provider={{ ...provider, name: checkoutData?.provider }}
          />
        );
      case "STRIPE":
        return (
          <StripeCheckout
            {...checkoutData}
            isGlobalCheckout={true}
            metaData={metaData}
            provider={{ ...provider, name: checkoutData?.provider }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Title>
            <Payment sx={{ mr: 2 }} />{" "}
            {checkoutData?.appTitle || checkoutData?.provider} Checkout
          </Title>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <AppWidgetSummary
              title="Amount"
              total={getAmount()}
              icon={"ant-design:credit-card"}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppWidgetSummary
              title="Payment Method"
              total={checkoutData?.provider}
              icon={"ant-design:credit-card"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {renderProvider()}
          </Grid>
          <Grid item md={12} xs={12}>
            {ValidationService.isString(checkoutData?.metaData?.description) ? (
              <Title
                sx={{
                  p: 2,
                  background: "#ddd",
                  minHeight: "200px",
                  maxHeight: "400px",
                  overflowY: "auto",
                  alignItems: "flex-start",
                }}
              >
                {checkoutData?.metaData?.description}
              </Title>
            ) : null}
          </Grid>
        </Grid>
        <Dialog open={!isConnected}>
          <DialogContent>
            <Message
              severity="warning"
              message={
                <Title>
                  <Wifi /> Trying to establish connection
                </Title>
              }
            />
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}

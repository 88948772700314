import { ToastProvider, DefaultToast } from "react-toast-notifications";

const CustomToast = ({ children, ...rest }) => (
  <div style={{ zIndex: 1201 }}>
    <DefaultToast {...rest} style={{ zIndex: 1201 }}>
      {children}
    </DefaultToast>
  </div>
);

export default function withSnackbar(
  Component,
  {
    placement = "bottom-right",
    autoDismiss = true,
    autoDismissTimeout = 3000,
  } = {}
) {
  return (props) => {
    return (
      <ToastProvider
        id="test"
        placement={placement}
        autoDismiss={autoDismiss}
        autoDismissTimeout={autoDismissTimeout}
        components={{ Toast: CustomToast }}
      >
        <Component {...props} />
      </ToastProvider>
    );
  };
}

import { Grid, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { AppCurrentVisits } from "../../sections/@dashboard/app";

export default function DashboardProviderInfo({
  provider,
  data,
  type = "Transaction",
}) {
  const theme = useTheme();
  const buildChartData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data
      .filter((x) => x?.provider?.name === provider)
      .map(({ status, count }) => ({ label: status, value: count }));
  }, [data, provider]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AppCurrentVisits
          title={`${type} Statuses (${provider})`}
          chartData={buildChartData}
          chartColors={[
            theme.palette.primary.main,
            theme.palette.info.main,
            theme.palette.warning.main,
            theme.palette.error.main,
          ]}
        />
      </Grid>
    </Grid>
  );
}

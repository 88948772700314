import { Add } from "@mui/icons-material";
import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApplication } from "../../store/AppContext";
import RestTable from "../tables/rest/RestTable";
import { APPLICATIONS_TABLE_COLUMNS } from "./constants";

export default function ApplicationsListView() {
  const { page, view, total, applications, getApplications } = useApplication();
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <RestTable
            title={"Applications"}
            rows={applications}
            columns={APPLICATIONS_TABLE_COLUMNS}
            onPageChange={async (page) => {
              await getApplications(page, view);
            }}
            onViewChange={async (view) => {
              await getApplications(page, view);
            }}
            count={total}
            view={view}
            page={page}
            disableSelection
            renderToolbarChildren={() => {
              return (
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={() => navigate("/new-application")}
                    >
                      New Application
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

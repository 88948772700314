import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, Money, Payment } from "@mui/icons-material";
import { useApplication } from "../../store/AppContext";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

const ITEM_HEIGHT = 48;

export default function AppMenuActions({ row }) {
  const navigate = useNavigate();
  const { deleteApplication } = useApplication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={() => navigate(`/manage-application/${row._id}`)}>
          <Edit /> Edit
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/manage-application/${row._id}/transactions`)
          }
        >
          <Payment /> Transactions
        </MenuItem>
        <MenuItem
          onClick={() => navigate(`/manage-application/${row._id}/payouts`)}
        >
          <Money /> Payouts
        </MenuItem>
        <MenuItem onClick={() => setDeleteId(row._id)}>
          <Delete /> Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId(null)}
        onConfirm={async () => {
          await deleteApplication(row._id);
          setDeleteId(null);
        }}
      />
    </div>
  );
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

export default function Modal({
  open,
  children,
  title,
  renderActions,
  sx,
  titleSx,
  contentSx,
  actionsSx,
  fullWidth = true,
  fullScreen,
  disableBackdropClick,
  onClose,
  ...rest
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={sx}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      {...rest}
      onClose={disableBackdropClick ? () => {} : onClose}
    >
      {title ? (
        <DialogTitle sx={{ fontSize: "1.2rem", ...titleSx }}>
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent sx={contentSx}>{children}</DialogContent>
      {renderActions ? (
        <DialogActions sx={actionsSx}>{renderActions()}</DialogActions>
      ) : null}
    </Dialog>
  );
}

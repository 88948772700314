import { Grid } from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { useState } from "react";
import { useApplication } from "../store/AppContext";

export default function PaypalCheckout({
  amount,
  provider,
  currency = "EUR",
  metaData = {},
  isGlobalCheckout,
  ...rest
}) {
  const { createNotification } = useApplication();
  const [orderID, setOrderID] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [hideBtn, setHideBtn] = useState(false);
  const handleCreateOrder = async () => {
    try {
      const res = await axios.post(process.env.REACT_APP_BASE_PROCESSOR_URL, {
        amount,
        currency,
        payment_method: "PAYPAL",
        command: "CREATE_PAYMENT_INTENT",
        provider: "PAYPAL",
        public_key: provider?.public_key,
        metaData,
      });
      setTransaction(transaction);
      setOrderID(res.data.id);
      return [undefined, res.data];
    } catch (error) {
      return [error, undefined];
    }
  };

  const handleCompleteOrder = async (orderId) => {
    try {
      setHideBtn(true);
      const res = await axios.post(process.env.REACT_APP_BASE_PROCESSOR_URL, {
        orderId,
        command: "CAPTURE_PAYMENT_INTENT",
        provider: "PAYPAL",
        public_key: provider?.public_key,
        metaData,
      });
      createNotification("Payment completed");
      return [undefined, res.data];
    } catch (error) {
      return [error, undefined];
    }
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const res = await axios.post(process.env.REACT_APP_BASE_PROCESSOR_URL, {
        orderId,
        command: "CANCEL_PAYMENT_INTENT",
        provider: "PAYPAL",
        public_key: provider?.public_key,
        metaData,
      });
      createNotification("Payment canceled", "info");
      return [undefined, res.data];
    } catch (error) {
      return [error, undefined];
    }
  };

  // const handleLogPayment = async (details, orderId) => {
  //   try {
  //     const res = await axios.post(process.env.REACT_APP_BASE_PROCESSOR_URL, {
  //       command: "LOG_PAYMENT_DETAILS",
  //       provider: "PAYPAL",
  //       public_key: provider?.public_key,
  //       orderId,
  //       metaData,
  //       details,
  //     });
  //     return [undefined, res.data];
  //   } catch (error) {
  //     return [error, undefined];
  //   }
  // };

  if (!provider) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <PayPalScriptProvider
          options={{ "client-id": provider?.public_key, currency }}
        >
          <PayPalButtons
            disabled={hideBtn}
            style={{
              layout: "horizontal",
              shape: "rect",
              label: "pay",
              tagline: false,
            }}
            createOrder={async (data, actions) => {
              const [error, { id }] = await handleCreateOrder();
              if (error) throw error;

              return id;
            }}
            onError={async (err) => {
              createNotification("Payment failed", "error");
              if (orderID) {
                await handleCancelOrder(orderID);
                setOrderID(null);
              }
              if (isGlobalCheckout) {
                window.location.replace(
                  `${metaData?.error_url}?appTitle=${rest?.appTitle}&provider=${provider?.name}&amount=${amount}${currency}&orderId=${orderID}`
                );
              }
            }}
            onCancel={async (data, context) => {
              const [error] = await handleCancelOrder(data.orderID);
              if (error) throw error;
              else {
                setOrderID(null);
                if (isGlobalCheckout) {
                  window.location.replace(
                    `${metaData?.error_url}?appTitle=${rest?.appTitle}&provider=${provider?.name}&amount=${amount}${currency}&orderId=${data.orderID}`
                  );
                }
              }
            }}
            onApprove={async (data, actions) => {
              // const [error] = await handleCompleteOrder(data.orderID);
              // if (error) throw error;
              return actions.order.capture().then(async (details) => {
                const [error] = await handleCompleteOrder(data.orderID);
                if (error) throw error;
                setOrderID(null);
                if (isGlobalCheckout) {
                  window.location.replace(
                    `${metaData?.success_url}?appTitle=${rest?.appTitle}&provider=${provider?.name}&amount=${amount}${currency}&orderId=${data.orderID}`
                  );
                }
              });
            }}
          />
        </PayPalScriptProvider>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";

export default function StripeCheckout({
  amount,
  provider,
  metaData,
  currency = "EUR",
  isGlobalCheckout,
  ...rest
}) {
  const [clientSecret, setClientSecret] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_BASE_PROCESSOR_URL, {
        command: "CREATE_PAYMENT_INTENT",
        amount,
        currency,
        payment_method: "CARD",
        provider: "STRIPE",
        public_key: provider?.public_key,
        metaData,
      })
      .then(({ data }) => {
        setClientSecret(data.client_secret);
        setOrderID(data.id);
        setLoading(false);
      })
      .catch((err) => {
        if (isGlobalCheckout) {
          window.location.replace(metaData?.error_url || "/404");
        }

        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => {
    return { clientSecret, appearance: { theme: "minimal" } };
  }, [clientSecret]);

  const stripePromise = useMemo(() => {
    if (!provider?.public_key) return;
    return loadStripe(provider?.public_key);
  }, [provider?.public_key]);

  if (!clientSecret) {
    return null;
  }

  if (!stripePromise) {
    return null;
  }

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 500000 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckoutForm
          isGlobalCheckout={isGlobalCheckout}
          setLoading={setLoading}
          clientSecret={clientSecret}
          metaData={metaData}
          provider={provider}
          amount={amount}
          currency={currency}
          orderID={orderID}
          loading={loading}
          {...rest}
        />
      </Elements>
    </>
  );
}

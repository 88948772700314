import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Block,
  Cancel,
  Check,
  Delete,
  SupervisedUserCircle,
} from "@mui/icons-material";
import { useApplication } from "../../store/AppContext";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

const ITEM_HEIGHT = 140;

export default function UserMenuActions({ row }) {
  const { approveUser, verifyUser, deleteUser, updateUserRole, user } =
    useApplication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDisabled = row?._id === user?._id;

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={async () =>
            await updateUserRole(
              row._id,
              row.role === "TENANT" ? "ADMIN" : "TENANT"
            )
          }
          disabled={!row.verified || !row.approved || isDisabled}
        >
          <SupervisedUserCircle /> Change Role To{" "}
          {row.role === "TENANT" ? "ADMIN" : "TENANT"}
        </MenuItem>
        <MenuItem
          onClick={async () => await approveUser(row._id, !row?.approved)}
          disabled={isDisabled || row?.role === "ADMIN"}
        >
          {row?.approved ? <Block color="error" /> : <Check color="success" />}{" "}
          {row?.approved ? "Disapprove" : "Approve"}
        </MenuItem>

        <MenuItem
          onClick={async () => await verifyUser(row._id, !row?.verified)}
          disabled={isDisabled || row?.role === "ADMIN"}
        >
          {row?.verified ? <Cancel color="error" /> : <Check color="success" />}{" "}
          {row?.verified ? "Unverify" : "Verify"}
        </MenuItem>
        <MenuItem
          onClick={() => setDeleteId(row?._id)}
          disabled={isDisabled || row?.role === "ADMIN"}
        >
          <Delete /> Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title={`User ${row?.email} is about to be deleted. Are you sure about this action?`}
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId(null)}
        onConfirm={async () => {
          const res = await deleteUser(deleteId);
          if (res) {
            setDeleteId(null);
          }
        }}
      />
    </div>
  );
}

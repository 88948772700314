import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useToasts } from "react-toast-notifications";
import Loader from "../components/ui/Loader";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const tokenExpired = (token) => {
  try {
    if (!token) {
      return true;
    }
    const decoded = jwtDecode(token);
    if (
      typeof decoded?.exp !== "undefined" &&
      Date.now() >= decoded?.exp * 1000
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};
export const getLocalStorageItem = (key, isJson = false) => {
  if (window && typeof window.localStorage !== "undefined") {
    const entry = window.localStorage.getItem(key);

    return isJson && entry ? JSON.parse(entry) : entry;
  }
};

export const setLocalStorageItem = (key, value, isJson = false) => {
  if (window && typeof window.localStorage !== "undefined") {
    window.localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
  }
};

export const getCredentials = () => {
  try {
    return getLocalStorageItem("token", true);
  } catch (error) {
    return null;
  }
};

export const getAccessToken = async (refreshToken) => {
  try {
    const res = await axios
      .get(`${baseUrl}/auth/refresh-token`, {
        headers: {
          "Refresh-Token": `Bearer ${refreshToken}`,
        },
      })
      .catch((err) => {
        deleteLocalStorageItem("token");
        throw err;
      });
    setLocalStorageItem("token", res.data, true);
    return res.data.access_token;
  } catch (error) {
    deleteLocalStorageItem("token");
    throw new Error();
  }
};

export const deleteLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};
axios.interceptors.request.use(async (config) => {
  const credentials = getCredentials();
  if (!credentials) {
    return config;
  }

  const { access_token } = credentials;

  config.headers["Authorization"] = `Bearer ${access_token}`;

  return config;
});

// Axios interceptor to handle token refresh and request retries
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const credentials = getCredentials();

    // Check if the request failed due to an invalid access token
    if (
      credentials &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      deleteLocalStorageItem("token");
      if (!credentials) {
        throw new Error();
      }

      if (
        tokenExpired(credentials.access_token) &&
        tokenExpired(credentials.refresh_token)
      ) {
        throw new Error();
      }

      try {
        const refreshToken = credentials.refresh_token;
        const accessToken = await getAccessToken(refreshToken);

        // Update the Authorization header with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        // Retry the original request
        return axios(originalRequest);
      } catch (refreshError) {
        deleteLocalStorageItem("token");
        throw refreshError; // Throw the error if refreshing the token fails
      }
    }
    deleteLocalStorageItem("token");

    return Promise.reject(error); // Throw the original error if it's not related to the access token
  }
);

export const ApplicationContext = createContext({});

export const useApplication = () => useContext(ApplicationContext);

const initialState = {
  user: null,
  token: null,
  applications: null,
  application: null,
  isAuthenticated: false,
  authLoading: false,
  applicationsLoading: false,
  apiKeysLoading: false,
  transactions: null,
  payouts: null,
  metaData: null,
  page: 1,
  view: 10,
  total: 0,
  limit: 0,
  dashboardData: null,
  dashboardPayoutsData: null,
  appMetaData: null,
  appPayoutsMetaData: null,
  api_keys: [],
  users: null,
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "APPLICATIONS_LOADING":
      return {
        ...state,
        applicationsLoading: action.payload,
      };
    case "SET_APPLICATIONS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_APPLICATION":
      return {
        ...state,
        application: action.payload,
      };

    case "AUTH_LOADING":
      return {
        ...state,
        authLoading: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_METADATA":
      return {
        ...state,
        metaData: action.payload,
      };
    case "SET_TRANSACTIONS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_PAYOUTS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_DASHBOARD_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_APPLICATION_AGGREGATED_DATA": {
      return {
        ...state,
        appMetaData: action.payload,
      };
    }
    case "SET_APPLICATION_PAYOUTS_AGGREGATED_DATA": {
      return {
        ...state,
        appPayoutsMetaData: action.payload,
      };
    }
    case "SET_API_KEYS_LOADING": {
      return {
        ...state,
        apiKeysLoading: action.payload,
      };
    }
    case "SET_API_KEYS": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SET_USERS": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "LOGOUT":
      console.log(initialState);
      return initialState;
    default:
      return state;
  }
};

const ApplicationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();

  const createNotification = (message, variant = "success") => {
    addToast(message, { appearance: variant });
  };

  const setMetaData = useCallback(
    (metaData) => dispatch({ type: "SET_METADATA", payload: metaData }),
    []
  );

  const setAuthLoading = useCallback(
    (loading) => dispatch({ type: "AUTH_LOADING", payload: loading }),
    []
  );

  const setApplicationsLoading = useCallback(
    (loading) => dispatch({ type: "APPLICATIONS_LOADING", payload: loading }),
    []
  );

  const setUser = useCallback((token) => {
    dispatch({
      type: "SET_USER",
      payload: { token, user: jwtDecode(token), isAuthenticated: true },
    });
  }, []);

  const logout = useCallback(async () => {
    try {
      setAuthLoading(true);
      const { refresh_token } = getCredentials() || {};
      await axios.post(`${baseUrl}/auth/logout`, { refresh_token });
    } catch (error) {
    } finally {
      dispatch({
        type: "LOGOUT",
        payload: { token: null, user: null, isAuthenticated: false },
      });
      localStorage.removeItem("token");
      navigate("/login");
      setAuthLoading(false);
    }
  }, [navigate, setAuthLoading]);

  const login = async (form) => {
    let res;
    try {
      setAuthLoading(true);
      const res = await axios.post(`${baseUrl}/auth/login`, form);
      setUser(res.data.access_token);
      setLocalStorageItem("token", res.data, true);
      axios
        .get(`${baseUrl}/applications/static`)
        .then((res) => {
          setMetaData(res.data);
          setAuthLoading(false);
        })
        .catch((error) => {
          setAuthLoading(false);
          navigate("/404");
        });
      navigate("/");
      createNotification("Welcome");
    } catch (error) {
      createNotification(
        error?.response?.data?.message || "Login Failed",
        "error"
      );
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const register = async (form) => {
    let res;
    try {
      setAuthLoading(true);
      await axios.post(`${baseUrl}/auth/register`, form);
      navigate("/login");
      createNotification(
        "Account created! Please wait until your account is approved"
      );
    } catch (error) {
      createNotification("Registration Failed", "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const setApplications = (data) =>
    dispatch({ type: "SET_APPLICATIONS", payload: data });

  const getApplications = async (page = 1, view = 10) => {
    try {
      setApplicationsLoading(true);
      const { data } = await axios.get(`${baseUrl}/applications`, {
        params: {
          page,
          view,
        },
      });
      setApplications(data);
    } catch (error) {
    } finally {
      setApplicationsLoading(false);
    }
  };

  const setApplication = (application) =>
    dispatch({ type: "SET_APPLICATION", payload: application });

  const getApplication = async (_id) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/applications/${_id}`);
      setApplication(res.data);
    } catch (error) {
      navigate("/app");
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const createNewApplication = async (form) => {
    let res;
    try {
      const { configuration, ...rest } = form;
      setApplicationsLoading(true);
      res = await axios.post(`${baseUrl}/applications`, {
        ...rest,
        configuration: { providers: configuration },
      });
      createNotification("Application Created");
    } catch (error) {
      createNotification("Application was not Created");
    } finally {
      if (res) {
        navigate(`/manage-application/${res?.data?._id}`);
      }
      setApplicationsLoading(false);
    }
  };

  const updateApplication = async (form) => {
    let res;
    try {
      setApplicationsLoading(true);
      const { _id, configuration, ...rest } = form;
      res = await axios.put(`${baseUrl}/applications/${_id}`, {
        ...rest,
        configuration: { providers: configuration },
      });
      await getApplication(_id);
      createNotification("Application updated");
    } catch (error) {
      createNotification("Application was not updated", "error");
    } finally {
      setApplicationsLoading(false);
      return res?.data;
    }
  };

  const deleteApplication = async (_id) => {
    let res;
    try {
      setApplicationsLoading(true);

      res = await axios.delete(`${baseUrl}/applications/${_id}`, {
        data: { _id },
      });
      await getApplications(state.page, state.view);
      createNotification("Application Deleted");
    } catch (error) {
      createNotification("Application was not deleted", "error");
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const setTransactions = (data) => {
    dispatch({ type: "SET_TRANSACTIONS", payload: data });
  };

  const setPayouts = (data) => {
    dispatch({ type: "SET_PAYOUTS", payload: data });
  };

  const getTransactions = async (appId, page, view, identifier) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/applications/${appId}/transactions`, {
        params: { page, view, identifier: identifier || undefined },
      });
      setTransactions(res.data);
    } catch (error) {
      navigate("/");
    } finally {
      setApplicationsLoading(false);
    }
  };

  const getPayouts = async (appId, page, view, identifier) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/applications/${appId}/payouts`, {
        params: { page, view, identifier: identifier || undefined },
      });
      setPayouts(res.data);
    } catch (error) {
      navigate("/");
    } finally {
      setApplicationsLoading(false);
    }
  };

  const setAppMetaData = (metadata) => {
    dispatch({ type: "SET_APPLICATION_AGGREGATED_DATA", payload: metadata });
  };

  const setAppPayoutsMetaData = (metadata) => {
    dispatch({
      type: "SET_APPLICATION_PAYOUTS_AGGREGATED_DATA",
      payload: metadata,
    });
  };

  const getAppMetaData = async (id) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/dashboard/application/${id}`);
      setAppMetaData(res.data.aggregate);
      setAppPayoutsMetaData(res.data.payoutsAggregate);
    } catch (error) {
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const getDashboardData = async () => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/dashboard`);
      dispatch({
        type: "SET_DASHBOARD_DATA",
        payload: {
          dashboardData: res.data.aggregate,
          dashboardPayoutsData: res.data.payoutsAggregate,
        },
      });
    } catch (error) {
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const setApiKeysLoading = (loading) =>
    dispatch({ type: "SET_API_KEYS_LOADING", payload: loading });

  const setApiKeys = (data) =>
    dispatch({ type: "SET_API_KEYS", payload: data });

  const getApiKeys = async (page, view) => {
    let res;
    try {
      setApiKeysLoading(true);
      res = await axios.get(`${baseUrl}/api-keys`, { params: { page, view } });
      setApiKeys(res.data);
    } catch (error) {
    } finally {
      setApiKeysLoading(false);
      return res;
    }
  };

  const createApiKey = async (api_key) => {
    let res;
    try {
      setApiKeysLoading(true);
      res = await axios.post(
        `${baseUrl}/api-keys${api_key ? "/manual" : ""} `,
        { api_key }
      );
      await getApiKeys(state.page, state.view);
      createNotification("A New API Key was created", "success");
    } catch (error) {
      createNotification("Api Key was not created", "error");
    } finally {
      setApiKeysLoading(false);
      return res;
    }
  };

  const deleteApiKey = async (id) => {
    let res;
    try {
      setApiKeysLoading(true);
      res = await axios.delete(`${baseUrl}/api-keys/${id}`);
      createNotification("API Key deleted!", "success");
      await getApiKeys(state.page, state.view);
    } catch (error) {
      createNotification("API Key was not deleted!", "error");
    } finally {
      setApiKeysLoading(false);
      return res;
    }
  };

  const setUsers = (data) => dispatch({ type: "SET_USERS", payload: data });

  const getUsers = async (page, view) => {
    let res;
    try {
      const { user } = state;
      if (user.role !== "ADMIN") {
        navigate("/app");
        return;
      }
      setAuthLoading(true);
      res = await axios.get(`${baseUrl}/admin/users`, {
        params: { page, view },
      });
      setUsers(res.data);
    } catch (error) {
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const approveUser = async (_id, approved) => {
    let res;
    try {
      const { user } = state;
      if (user.role !== "ADMIN") {
        navigate("/app");
        return;
      }
      setAuthLoading(true);
      res = await axios.put(`${baseUrl}/admin/users/${_id}/approve`, {
        approved: Boolean(approved),
      });
      await getUsers(state.page, state.view);
      createNotification(
        `User status was set to ${approved ? "Approved" : "Disapproved"}`,
        "success"
      );
    } catch (error) {
      createNotification(`User status was not updated`, "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const verifyUser = async (_id, verified) => {
    let res;
    try {
      const { user } = state;
      if (user.role !== "ADMIN") {
        navigate("/app");
        return;
      }
      setAuthLoading(true);
      res = await axios.put(`${baseUrl}/admin/users/${_id}/verify`, {
        verified: Boolean(verified),
      });
      await getUsers(state.page, state.view);
      createNotification(
        `User account was set to ${verified ? "Verified" : "Not Verified"}`,
        "success"
      );
    } catch (error) {
      createNotification(`User account was not updated`, "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const updateUserRole = async (_id, role) => {
    let res;
    try {
      const { user } = state;
      if (user.role !== "ADMIN") {
        navigate("/app");
        return;
      }
      setAuthLoading(true);
      res = await axios.put(`${baseUrl}/admin/users/${_id}/role`, {
        role,
      });
      await getUsers(state.page, state.view);
      createNotification(`User Role successfully updated`, "success");
    } catch (error) {
      createNotification(`User Role was not updated`, "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const deleteUser = async (_id, verified) => {
    let res;
    try {
      const { user } = state;
      if (user.role !== "ADMIN") {
        navigate("/app");
        return;
      }
      setAuthLoading(true);
      res = await axios.delete(`${baseUrl}/admin/users/${_id}`);
      await getUsers(state.page, state.view);
      createNotification(`User Deleted!`, "success");
    } catch (error) {
      createNotification(`User was not deleted`);
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const updateProfile = async (form) => {
    let res;
    try {
      setAuthLoading(true);
      res = await axios.put(`${baseUrl}/profile`, form);
      const { data } = await axios.get(`${baseUrl}/auth`);
      setUser(data.access_token);
      createNotification("Profile updated!", "success");
    } catch (error) {
      createNotification("Profile was not updated!", "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const changePassword = async (form) => {
    let res;
    try {
      setAuthLoading(true);

      res = await axios.put(`${baseUrl}/profile/change-password`, form);

      createNotification("Password was changed", "success");
      createNotification("Now you will log out", "info");
      logout();
    } catch (error) {
      createNotification("Password was not changed", "error");
    } finally {
      setAuthLoading(false);
      return res;
    }
  };

  const initializePublicCheckout = async (data) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.post(`${baseUrl}/checkout`, data);
    } catch (error) {
    } finally {
      setApplicationsLoading(false);
      if (res?.data) {
        window.location.replace(res.data.url);
      }
    }
  };

  const derivePublicCheckoutMetaData = async (key) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/checkout/${key}`);
    } catch (error) {
      navigate("/404");
    } finally {
      setApplicationsLoading(false);
      return res?.data;
    }
  };

  const loadDocumentation = async () => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.get(`${baseUrl}/docs`);
    } catch (error) {
      navigate("/404");
    } finally {
      setApplicationsLoading(false);
      return res?.data;
    }
  };

  const deleteTransaction = async ({ identifier, appId, page, view }) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.delete(
        `${baseUrl}/applications/transactions/${identifier}`
      );
      createNotification("Transaction deleted", "success");
      await getTransactions(appId, page, view);
    } catch (error) {
      createNotification("Transaction was not deleted", "error");
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const deletePayout = async ({ identifier, appId, page, view }) => {
    let res;
    try {
      setApplicationsLoading(true);
      res = await axios.delete(`${baseUrl}/applications/payouts/${identifier}`);
      createNotification("Payout deleted", "success");
      await getPayouts(appId, page, view);
    } catch (error) {
      createNotification("Payout was not deleted", "error");
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const createAccountVerification = async () => {
    setApplicationsLoading(true);
    let res;
    try {
      res = await axios.post(`${baseUrl}/profile/verify-account`, {});
      createNotification(
        "A verification mail has been sent to your inbox",
        "success"
      );
    } catch (error) {
      createNotification(
        error?.response?.data?.message ||
          "Verification mail was not sent.Please try again later",
        "error"
      );
    } finally {
      setApplicationsLoading(false);
      return res;
    }
  };

  const shouldNotContinue = () =>
    location?.pathname?.startsWith("/checkout") ||
    location?.pathname?.startsWith("/checkout-success") ||
    location?.pathname?.startsWith("/checkout-error");

  useEffect(() => {
    if (shouldNotContinue()) {
      return;
    }
    if (!localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
    setAuthLoading(true);
    axios
      .get(`${baseUrl}/auth`)
      .then((res) => {
        setUser(res.data.access_token);
        setLocalStorageItem("token", res.data, true);
        axios
          .get(`${baseUrl}/applications/static`)
          .then((res) => {
            setMetaData(res.data);
            setAuthLoading(false);
          })
          .catch((error) => {
            setAuthLoading(false);
            if (!location?.pathname?.startsWith("/checkout/")) {
              navigate("/404");
            }
          });
      })
      .catch((err) => {
        logout();
        setAuthLoading(false);
        if (!location?.pathname?.startsWith("/checkout/")) {
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;
    try {
      if (shouldNotContinue()) {
        return;
      }
      const { refresh_token } = getCredentials();
      if (!refresh_token) {
        return;
      }
      if (tokenExpired(refresh_token)) {
        logout();
      }
      interval = setInterval(async () => {
        const { refresh_token } = getCredentials() || {};
        if (!refresh_token) {
          logout();
          return;
        }
        if (tokenExpired(refresh_token)) {
          logout();
          return;
        }
        await getAccessToken(refresh_token).catch((err) => {
          logout();
        });
      }, 60 * 1 * 1000);
    } catch (error) {}
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [logout, shouldNotContinue]);

  const { authLoading, applicationsLoading, apiKeysLoading } = state;

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        dispatch,
        logout,
        setAuthLoading,
        login,
        register,
        getApplications,
        getApplication,
        setApplication,
        createNewApplication,
        updateApplication,
        deleteApplication,
        getTransactions,
        createNotification,
        getDashboardData,
        setAppMetaData,
        getAppMetaData,
        setApiKeysLoading,
        setApiKeys,
        getApiKeys,
        createApiKey,
        deleteApiKey,
        getUsers,
        setUser,
        approveUser,
        verifyUser,
        deleteUser,
        updateProfile,
        changePassword,
        initializePublicCheckout,
        derivePublicCheckoutMetaData,
        loadDocumentation,
        updateUserRole,
        deleteTransaction,
        setPayouts,
        getPayouts,
        setAppPayoutsMetaData,
        deletePayout,
        createAccountVerification,
      }}
    >
      <Loader loading={authLoading || applicationsLoading || apiKeysLoading} />
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContextProvider;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import PaypalCheckout from "../components/PaypalCheckout";
import StripeCheckout from "../components/StripeCheckout";
import SelectInput from "../components/ui/SelectInput";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { Payment } from "@mui/icons-material";
import ValidationService from "../services/ValidationService";

const allowedProviders = ["PAYPAL", "STRIPE"];

function TestApp() {
  const [provider, setProvider] = useState("PAYPAL");
  const {
    getApplication,
    setApplication,
    application,
    initializePublicCheckout,
  } = useApplication();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getApplication(id);
    return () => setApplication(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProvider = () => {
    switch (provider) {
      case "PAYPAL":
        return (
          <PaypalCheckout
            amount={100}
            provider={application?.configuration?.providers?.find(
              (p) => p.provider.name === "PAYPAL"
            )}
            currency={"EUR"}
            metaData={{
              user_id: 12,
              email: "dim@gmail.com",
              public_key: application?.configuration?.providers?.find(
                (p) => p.provider.name === "PAYPAL"
              )?.public_key,
            }}
          />
        );
      case "STRIPE":
        return (
          <StripeCheckout
            amount={100}
            provider={application?.configuration?.providers?.find(
              (p) => p.provider.name === "STRIPE"
            )}
            currency={"EUR"}
            metaData={{
              user_id: 12,
              email: "dim@gmail.com",
              public_key: application?.configuration?.providers?.find(
                (p) => p.provider.name === "STRIPE"
              )?.public_key,
            }}
          />
        );
      default:
        return null;
    }
  };

  if (!application) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title={application?.name}
              icon={"ant-design:appstore"}
            >
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/manage-application/${application._id}`)
                }
              >
                Manage Application
              </Button>
            </AppWidgetSummary>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader title="Test Public Checkout" />
            <CardContent>
              <Button
                variant="contained"
                startIcon={<Payment />}
                onClick={() =>
                  initializePublicCheckout({
                    amount: 100,
                    currency: "EUR",
                    provider: "PAYPAL",
                    public_key: application?.configuration?.providers?.find(
                      (p) => p.provider.name === "PAYPAL"
                    )?.public_key,
                    metaData: {
                      description: "Test payments description",
                      user_id: 12,
                      email: "test@gmail.com",
                      public_key: application?.configuration?.providers?.find(
                        (p) => p.provider.name === "STRIPE"
                      )?.public_key,
                    },
                  })
                }
                sx={{ mr: 2 }}
              >
                PAYPAL
              </Button>
              <Button
                variant="contained"
                startIcon={<Payment />}
                onClick={() =>
                  initializePublicCheckout({
                    amount: 100,
                    currency: "EUR",
                    provider: "STRIPE",
                    public_key: application?.configuration?.providers?.find(
                      (p) => p.provider.name === "STRIPE"
                    )?.public_key,
                    metaData: {
                      description: "Test payments description",
                      user_id: 12,
                      email: "test@gmail.com",
                      public_key: application?.configuration?.providers?.find(
                        (p) => p.provider.name === "STRIPE"
                      )?.public_key,
                    },
                  })
                }
              >
                STRIPE
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Payment />
                </Avatar>
              }
              title={`100 €`}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <SelectInput
                    isTouched={false}
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                    options={allowedProviders
                      .filter((x) =>
                        application?.configuration?.providers?.some(
                          (p) =>
                            p?.provider?.name === x &&
                            !ValidationService.isNullOrUndefinedOrEmpty(
                              p.public_key
                            ) &&
                            !ValidationService.isNullOrUndefinedOrEmpty(
                              p.private_key
                            )
                        )
                      )
                      .map((x) => ({
                        label: x,
                        value: x,
                      }))}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {renderProvider()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withAuth(TestApp);

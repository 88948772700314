import { Grid, Paper } from "@mui/material";
import React from "react";
import { useApplication } from "../../store/AppContext";
import RestTable from "../tables/rest/RestTable";
import { USERS_TABLE_COLUMNS } from "./constants";

export default function UsersListView() {
  const { page, view, total, users, getUsers } = useApplication();

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <RestTable
            title={"Users"}
            rows={users}
            columns={USERS_TABLE_COLUMNS}
            onPageChange={async (page) => {
              await getUsers(page, view);
            }}
            onViewChange={async (view) => {
              await getUsers(page, view);
            }}
            count={total}
            view={view}
            page={page}
            disableSelection
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

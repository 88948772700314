import { useMemo } from "react";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { useApplication } from "../../store/AppContext";

const buildInitialProviders = (metaData) => {
  if (!metaData) return [];
  const {
    providers = [],
    providersConfig,
    events = [],
    httpMethods = [],
  } = metaData || {};
  return providers.map((provider) => {
    return {
      provider: providersConfig[provider],
      public_key: "",
      private_key: "",
      api_key: "",
      webhook_key: "",
      public_checkout_error_redirect_url: "",
      public_checkout_success_redirect_url: "",
      sandbox: true,
      events: events.map((event) => {
        return {
          name: event,
          active: false,
          endpoint: "",
          method: httpMethods?.find((x) => x === "POST"),
          forwardAuthHeaders: false,
        };
      }),
    };
  });
};

const formatAppProviders = (appProviders, metaData) => {
  if (!metaData) return [];
  const {
    providers = [],
    providersConfig,
    events = [],
    httpMethods = [],
  } = metaData || {};
  return providers.map((provider) => {
    const appProvider = appProviders.find((p) => p.provider.name === provider);

    if (appProvider) {
      return {
        ...appProvider,
        events: events.map((event) => {
          const appEvent = (appProvider.events || []).find(
            (e) => e.name === event
          );
          if (appEvent) {
            return { ...appEvent };
          }
          return {
            name: event,
            active: false,
            endpoint: "",
            method: httpMethods?.find((x) => x === "POST"),
            forwardAuthHeaders: false,
          };
        }),
      };
    }
    return {
      provider: providersConfig[provider],
      public_key: "",
      private_key: "",
      api_key: "",
      webhook_key: "",
      public_checkout_error_redirect_url: "",
      public_checkout_success_redirect_url: "",
      events: events.map((event) => {
        return {
          name: event,
          active: false,
          endpoint: "",
          method: httpMethods?.find((x) => x === "POST"),
          forwardAuthHeaders: false,
        };
      }),
    };
  });
};

export default function useApplicationForm(application) {
  const { metaData } = useApplication();

  const config = useMemo(() => {
    if (application && application?.configuration?.providers) {
      return formatAppProviders(
        application?.configuration?.providers,
        metaData
      );
    }

    return buildInitialProviders(metaData);
  }, [application, metaData]);

  return useForm({
    _id: {
      defaultValue: application?._id,
    },
    title: {
      defaultValue: application?.title || "",
      validators: (value) =>
        ValidationService.validateString({
          value,
          min: 2,
          max: 30,
        }),
    },
    name: {
      defaultValue: application?.name || "",
      formatter: (value) =>
        ValidationService.isString(value) ? value.trim() : "",
      validators: (value) =>
        ValidationService.validateString({
          value,
          min: 2,
          max: 30,
          noWhiteSpace: true,
        }),
    },
    status: {
      defaultValue: application?.status || metaData?.applicationStatuses?.[0],
      validators: (value) =>
        ValidationService.isOneOf({
          value,
          options: metaData?.applicationStatuses || [],
        }),
    },
    configuration: {
      defaultValue: config,
    },
  });
}

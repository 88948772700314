import { Helmet } from "react-helmet-async";
import {
  Grid,
  Container,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import ApplicationForm from "../components/applications/ApplicationForm";
import { useApplication } from "../store/AppContext";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppsOutlined,
  Info,
  Money,
  Payment,
  Settings,
} from "@mui/icons-material";
import AppInfo from "../components/applications/AppInfo";
import Title from "../components/ui/Title";
import ListView from "../components/ui/ListView";

function ManageApplication() {
  const {
    metaData,
    getApplication,
    application,
    updateApplication,
    setApplication,
  } = useApplication();
  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);

  useEffect(() => {
    getApplication(id);
    return () => {
      setApplication(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionsMenuItems = useMemo(() => {
    return [
      {
        primary: "Transactions",
        icon: <Payment />,
        onClick: () =>
          navigate(`/manage-application/${application._id}/transactions`),
      },
      {
        primary: "Payouts",
        icon: <Money />,
        onClick: () =>
          navigate(`/manage-application/${application._id}/payouts`),
      },
      {
        primary: "Test Payments",
        icon: <Payment />,
        onClick: () => navigate(`/manage-application/${application._id}/test`),
      },
    ];
  }, [application?._id, navigate]);

  if (!application) {
    return null;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Helmet>
        <title> Manage Application </title>
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title={application?.name}
              icon={"ant-design:appstore"}
            >
              <Tooltip title="Actions">
                <IconButton onClick={() => setOpenActions(true)}>
                  <Settings />
                </IconButton>
              </Tooltip>
              <Tooltip title="Application Info">
                <IconButton onClick={() => setOpen(true)}>
                  <Info />
                </IconButton>
              </Tooltip>
            </AppWidgetSummary>
          </Grid>
          <Grid item md={12} xs={12}>
            {metaData && application ? (
              <ApplicationForm
                application={application}
                onSubmit={updateApplication}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>
          <Title divider>
            <AppsOutlined sx={{ mr: 2 }} /> <h5>{application?.name}</h5>
          </Title>
        </DialogTitle>
        <DialogContent>
          <AppInfo />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openActions}
        onClose={() => setOpenActions(false)}
        fullWidth
      >
        <DialogTitle>
          <Title divider>
            <AppsOutlined sx={{ mr: 2 }} /> <h5>{application?.name}</h5>
          </Title>
        </DialogTitle>
        <DialogContent>
          <ListView button items={actionsMenuItems} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenActions(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default withAuth(ManageApplication);

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplication } from "../store/AppContext";

export default function withAuth(Component) {
  return (props) => {
    const { isAuthenticated, user, logout } = useApplication();

    const navigate = useNavigate();
    useEffect(() => {
      if (!localStorage.getItem("token")) {
        navigate("/login");
        logout();
      }
    }, [logout, navigate]);

    if (!isAuthenticated || !localStorage.getItem("token")) {
      return null;
    }

    if (!user) {
      return null;
    }

    return <Component {...props} />;
  };
}

import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import CustomSwitch from "../ui/Switch";
import { Button } from "@mui/material";

export default function ConfirmationDialog({
  onCancel,
  onConfirm,
  disableConfirm,
  open,
  title = "Confirmation Needed For This Action",
  disableBackdropClick,
  children,
  ...rest
}) {
  const [confirm, setConfirm] = useState(false);

  const renderActions = () => {
    return (
      <>
        <Button
          onClick={() => {
            setConfirm(false);
            if (onCancel) onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disableConfirm || !confirm}
          onClick={() => {
            onConfirm();
            setConfirm(false);
          }}
        >
          Confirm
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!open) {
      setConfirm(false);
    }
  }, [open]);

  return (
    <Modal
      title={title}
      open={open}
      renderActions={renderActions}
      disableBackdropClick={disableBackdropClick}
      onClose={() => {
        if (onCancel) onCancel();
      }}
    >
      <CustomSwitch
        label={confirm ? "Yes" : "No"}
        checked={confirm}
        onChange={(e) => setConfirm(e.target.checked)}
        sx={{ my: 1 }}
      />
      {children}
    </Modal>
  );
}

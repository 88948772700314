import { Helmet } from "react-helmet-async";
import { Grid, Container } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import { useEffect } from "react";
import ApplicationsListView from "../components/applications/ApplicationsListView";

function ApplicationsPage() {
  const { getApplications, total } = useApplication();

  useEffect(() => {
    getApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> My Applications </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title="Applications"
              total={total}
              icon={"ant-design:appstore"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <ApplicationsListView />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(ApplicationsPage);

import { Payment } from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListView from "../components/ui/ListView";
import Title from "../components/ui/Title";

export default function withCheckoutLayout(Component) {
  return (props) => {
    const [query] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
      const appTitle = query.get("appTitle");
      const provider = query.get("provider");
      const amount = query.get("amount");

      if (!appTitle || !provider || !amount) {
        navigate("/404");
      }
    }, [navigate, query]);

    return (
      <>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Title>
              <Payment sx={{ mr: 2 }} /> {query.get("appTitle")} Checkout
            </Title>
          </Toolbar>
        </AppBar>
        <Component
          {...props}
          amount={query.get("amount")}
          provider={query.get("provider")}
          appTitle={query.get("appTitle")}
        >
          {props.children}
          <ListView
            button
            items={[
              {
                primary: query.get("amount"),
                icon: <Payment />,
                secondary: "Amount",
                divider: true,
              },
              {
                primary: query.get("provider"),
                icon: <Payment />,
                secondary: "Provider",
                divider: true,
              },
              {
                primary: query.get("appTitle"),
                icon: <Payment />,
                secondary: "Merchant",
                divider: true,
              },
            ]}
          />
        </Component>
      </>
    );
  };
}

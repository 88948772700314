import {
  ExpandMore,
  Http,
  Key,
  Link,
  Payment,
  SaveAlt,
  Webhook,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import React, { useMemo } from "react";
import { useApplication } from "../../store/AppContext";
import Input from "../forms/Input";
import SelectInput from "../ui/SelectInput";
import CustomSwitch from "../ui/Switch";
import Title from "../ui/Title";
import useApplicationForm from "./useApplicationForm";

export default function ApplicationForm({ application, onSubmit, onCancel }) {
  const formConfig = useApplicationForm(application);
  const { metaData } = useApplication();
  const { form, handleChange, handleBaseChange } = formConfig;

  const appStatusOptions = useMemo(() => {
    const { applicationStatuses = [] } = metaData || {};
    return applicationStatuses.map((x) => ({ label: x, value: x }));
  }, [metaData]);

  const httpOptions = useMemo(() => {
    const { httpMethods = [] } = metaData || {};
    return httpMethods.map((x) => ({ label: x, value: x }));
  }, [metaData]);

  const handleProviderChange = (idx) => (name, value) => {
    handleBaseChange(
      "configuration",
      form.configuration.value.map((config, i) =>
        idx === i
          ? {
              ...config,
              [name]: value,
            }
          : config
      )
    );
  };

  const handleEventChange = (providerIdx, eventIdx) => (name, value) => {
    handleBaseChange(
      "configuration",
      form.configuration.value.map((config, i) =>
        providerIdx === i
          ? {
              ...config,
              events: config.events.map((event, index) =>
                index === eventIdx
                  ? {
                      ...event,
                      [name]: value,
                    }
                  : event
              ),
            }
          : config
      )
    );
  };

  const handleCancel = () => {
    formConfig.resetForm();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ float: "right" }}>
        <Button
          variant="contained"
          onClick={handleCancel}
          disabled={!formConfig.isTouched}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item sx={{ float: "right" }}>
        <Button
          variant="contained"
          startIcon={<SaveAlt />}
          onClick={async () => {
            let res = await onSubmit(formConfig.getFormValues());
            if (res) {
              formConfig.resetIsTouched();
            }
          }}
          disabled={!formConfig.isValid || !formConfig.isTouched}
        >
          Save
        </Button>
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          {...form.name}
          name="name"
          value={form.name.value}
          onChange={handleChange}
          label={"Application Name"}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Input
          {...form.title}
          name="title"
          value={form.title.value}
          onChange={handleChange}
          label={"Application Title"}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SelectInput
          {...form.status}
          name="status"
          value={form.status.value}
          onChange={handleChange}
          label={"Application Status"}
          options={appStatusOptions}
        />
      </Grid>
      {(metaData?.providers || []).map((provider, idx) => {
        const formConfigProvider = form.configuration.value[idx];
        return (
          <Grid item md={12} xs={12} key={provider}>
            <Accordion elevation={2} defaultExpanded={idx === 0}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Title divider sx={{ justifyContent: "space-between" }}>
                  <Title>
                    <Payment sx={{ mr: 2 }} /> {provider}
                  </Title>
                </Title>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <CustomSwitch
                      label="Sandbox"
                      checked={!!formConfigProvider?.sandbox}
                      name="sandbox"
                      onChange={(e) => {
                        e.stopPropagation();
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.checked
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="public_key"
                      value={formConfigProvider.public_key}
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"Public Key"}
                      startIcon={<Key />}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="private_key"
                      value={formConfigProvider.private_key}
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"Private Key"}
                      startIcon={<Key />}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="webhook_key"
                      value={formConfigProvider.webhook_key}
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"Webhook Key"}
                      startIcon={<Key />}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="api_key"
                      value={formConfigProvider.api_key}
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"API Key"}
                      startIcon={<Key />}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="public_checkout_success_redirect_url"
                      value={
                        formConfigProvider.public_checkout_success_redirect_url
                      }
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"Success Redirect Url"}
                      startIcon={<Link />}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Input
                      isTouched={false}
                      name="public_checkout_error_redirect_url"
                      value={
                        formConfigProvider.public_checkout_error_redirect_url
                      }
                      onChange={(e) => {
                        handleProviderChange(idx)(
                          e.target.name,
                          e.target.value
                        );
                      }}
                      label={"Error Redirect Url"}
                      startIcon={<Link />}
                    />
                  </Grid>

                  {(metaData?.events || []).map((event, i) => {
                    const eventConfig = formConfigProvider.events[i];
                    if (!eventConfig) return null;
                    return (
                      <Grid item md={6} xs={12} key={event}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12}>
                                <Title divider>
                                  <Webhook sx={{ mr: 2 }} /> {event}
                                </Title>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Input
                                  isTouched={false}
                                  name="endpoint"
                                  value={eventConfig.endpoint}
                                  onChange={(e) => {
                                    handleEventChange(idx, i)(
                                      e.target.name,
                                      e.target.value
                                    );
                                  }}
                                  label={"HTTP Endpoint"}
                                  startIcon={<Http />}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <SelectInput
                                  isTouched={false}
                                  name="method"
                                  disabled
                                  options={httpOptions}
                                  value={eventConfig.method}
                                  onChange={(e) => {
                                    handleEventChange(idx, i)(
                                      e.target.name,
                                      e.target.value
                                    );
                                  }}
                                  label={"HTTP Method"}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomSwitch
                                  label={"Enabled"}
                                  name="active"
                                  checked={eventConfig.active}
                                  onChange={(e, value) => {
                                    handleEventChange(idx, i)("active", value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomSwitch
                                  label={"Forward Authentication Headers"}
                                  name="forwardAuthHeaders"
                                  checked={eventConfig.forwardAuthHeaders}
                                  onChange={(e, value) => {
                                    handleEventChange(idx, i)(
                                      "forwardAuthHeaders",
                                      value
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
    </Grid>
  );
}

import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DnDContainer = ({ onDragEnd, children, disabled }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            // style={getListStyle(snapshot.isDraggingOver)}
          >
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DnDItem = ({ children, id, index }) => (
  <Draggable key={id} draggableId={id} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        //   style={getItemStyle(
        //     snapshot.isDragging,
        //     provided.draggableProps.style
        //   )}
      >
        {children}
      </div>
    )}
  </Draggable>
);

export default function ListView({
  items,
  dataSet,
  dense,
  button,
  title,
  sx,
  fallbackMessage = null,
  secondarySx,
  children,
  onReorder,
}) {
  const ListItemComponent = button ? ListItemButton : ListItem;

  const onDragEnd = (result) => {
    if (!result.destination || !dataSet) {
      return;
    }
    const reorderItems = reorder(
      dataSet,
      result.source.index,
      result.destination.index
    );
    if (onReorder) onReorder(reorderItems);
  };

  return (
    <DnDContainer onDragEnd={onDragEnd} disabled={!onReorder}>
      <List
        dense={dense}
        sx={{
          bgcolor: "background.paper",
          p: 2,
          ...sx,
        }}
        subheader={
          title ? (
            <ListSubheader disableSticky component="div">
              {title}
            </ListSubheader>
          ) : undefined
        }
      >
        {!items?.length && fallbackMessage ? fallbackMessage : null}

        {(items || []).map((item, index) => (
          <DnDItem index={index} id={index?.toString()} key={index}>
            <ListItemComponent onClick={item?.onClick} divider={item.divider}>
              {item.icon ? <ListItemAvatar>{item.icon}</ListItemAvatar> : null}
              <ListItemText
                secondaryTypographyProps={secondarySx}
                secondary={item.secondary}
              >
                {item.primary}
              </ListItemText>
              {item.renderActions ? (
                <ListItemSecondaryAction>
                  {item.renderActions()}
                </ListItemSecondaryAction>
              ) : null}
            </ListItemComponent>
          </DnDItem>
        ))}
        {children}
      </List>
    </DnDContainer>
  );
}

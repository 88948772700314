import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete, Info } from "@mui/icons-material";
import { useApplication } from "../../store/AppContext";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

const ITEM_HEIGHT = 140;

const MenuActions = [
  { name: "info", label: "Payout Info", icon: <Info /> },
  { name: "delete", label: "Delete Payout", icon: <Delete /> },
];

export default function PayoutMenuActions({
  row,
  onSelect,
  onRefund,
  onDelete,
}) {
  const { deleteUser } = useApplication();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionToHandlerMap = {
    info: onSelect,
    refund: onRefund,
    delete: onDelete,
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        {MenuActions.map((item) => {
          return (
            <MenuItem
              key={item.name}
              onClick={() => {
                const handler = actionToHandlerMap?.[item.name];
                if (handler) {
                  handler(row);
                }
                handleClose();
              }}
            >
              {item.icon}
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmationDialog
        title={`Payout ${row?.identifier} is about to be deleted. Are you sure about this action?`}
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId(null)}
        onConfirm={async () => {
          const res = await deleteUser(deleteId);
          if (res) {
            setDeleteId(null);
          }
        }}
      />
    </div>
  );
}

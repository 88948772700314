export const PAYMENT_STATUSES = {
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  ERROR: "ERROR",
  PENDING: "PENDING",
  REFUNDED: "REFUNDED",
};

export const PAYOUT_STATUSES = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  ERROR: "ERROR",
  UNCLAIMED: "UNCLAIMED",
  CANCELED: "CANCELED",
  SENT: "SENT",
  CREATED: "CREATED",
};

export const PaymentStatuses = Object.values(PAYMENT_STATUSES);
export const PayoutStatuses = Object.values(PAYOUT_STATUSES);

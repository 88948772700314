import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { useApplication } from "../../store/AppContext";
import Input from "../forms/Input";

export default function ChangePasswordForm() {
  const { authLoading, changePassword } = useApplication();
  const [showPassword, setShowPassword] = useState(false);

  const formConfig = useForm({
    password: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => value === data.password2.value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return { [fieldName]: fieldValue, password2: form.password2.value };
      },
    },
    password2: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, data) => value === data.password2.value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return { [fieldName]: fieldValue, password: form.password.value };
      },
    },
    oldPassword: {
      defaultValue: "",
      validators: [
        (value) =>
          ValidationService.validateString({
            value,
            min: 8,
            max: 16,
            noWhiteSpace: true,
          }),
        (value, form) => form.password.value !== value,
      ],
      onChange: ({ fieldValue, form, fieldName }) => {
        return {
          [fieldName]: fieldValue,
          password: form.password.value,
          password2: form.password2.value,
        };
      },
    },
  });

  const handleSubmit = async () => {
    const formValues = formConfig.getFormValues();
    const res = await changePassword(formValues);
    if (res) {
      formConfig.resetForm();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Input
          {...formConfig.form.oldPassword}
          value={formConfig.form.oldPassword.value}
          onChange={formConfig.handleChange}
          label="Current Password"
          endIcon={!showPassword ? <VisibilityOff /> : <Visibility />}
          iconAction={() => setShowPassword((prev) => !prev)}
          type={showPassword ? "text" : "password"}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          {...formConfig.form.password}
          value={formConfig.form.password.value}
          onChange={formConfig.handleChange}
          label="New Password"
          endIcon={!showPassword ? <VisibilityOff /> : <Visibility />}
          iconAction={() => setShowPassword((prev) => !prev)}
          type={showPassword ? "text" : "password"}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          {...formConfig.form.password2}
          value={formConfig.form.password2.value}
          onChange={formConfig.handleChange}
          label="Verify New Password"
          endIcon={!showPassword ? <VisibilityOff /> : <Visibility />}
          iconAction={() => setShowPassword((prev) => !prev)}
          type={showPassword ? "text" : "password"}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Button
          onClick={handleSubmit}
          disabled={!formConfig.isValid || authLoading || !formConfig.isTouched}
          variant="contained"
          fullWidth
          startIcon={<Lock />}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

import { DataObjectOutlined, History, Info, Search } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AppOrderTimeline } from "../../sections/@dashboard/app";
import { useApplication } from "../../store/AppContext";
import RestTable from "../tables/rest/RestTable";
import TabContainer from "../tabs/TabContainer";
import Title from "../ui/Title";
import ReactJson from "react-json-view";

import { PAYOUTS_TABLE_COLUMNS, PayoutStatusToColorMap } from "./constants";
import Input from "../forms/Input";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function PayoutsListView() {
  const [identifier, setIdentifier] = useState(null);
  const { page, view, total, payouts, getPayouts, deletePayout } =
    useApplication();
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [deletedTransaction, setDeleteTransaction] = useState(null);

  const getFormattedSelectedRow = () => {
    if (!selectedRow) {
      return {};
    }
    try {
      const { application, provider, metadata, __v, ...rest } = selectedRow;

      return rest;
    } catch (error) {
      return {};
    }
  };

  const handleDelete = (row) => {
    setDeleteTransaction(row);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <RestTable
            title={"Payouts"}
            rows={payouts}
            columns={PAYOUTS_TABLE_COLUMNS}
            onPageChange={async (page) => {
              await getPayouts(id, page, view, identifier);
            }}
            onViewChange={async (view) => {
              await getPayouts(id, page, view, identifier);
            }}
            count={total}
            view={view}
            page={page}
            disableSelection
            // onRowClick={(row) => setSelectedRow(row)}
            columnProps={{
              actions: {
                onSelect: setSelectedRow,
                onDelete: handleDelete,
              },
            }}
            renderToolbarChildren={() => {
              return (
                <Grid container spacing={2}>
                  <Grid item>
                    <Input
                      isValid
                      fullWitdth
                      isTouched
                      label="Search Payout "
                      name="identifier"
                      value={identifier}
                      onChange={async (e) => {
                        if (!e.target.value) {
                          setIdentifier(undefined);
                          return await getPayouts(id, page, view, undefined);
                        }
                        setIdentifier(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<Search />}
                      variant="outlined"
                      onClick={async () => {
                        return await getPayouts(id, page, view, identifier);
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Paper>
      </Grid>
      <Dialog
        open={Boolean(selectedRow)}
        onClose={() => setSelectedRow(null)}
        fullWidth
        maxWidth="lg"
      >
        {Boolean(selectedRow) ? (
          <>
            <DialogTitle>
              <Title divider>
                <Info color="info" sx={{ mr: 2 }} /> Payout Info
              </Title>
            </DialogTitle>
            <DialogContent>
              <TabContainer
                tabs={[
                  { label: "History", name: "history", icon: <History /> },
                  {
                    name: "raw",
                    label: "Payload",
                    icon: <DataObjectOutlined />,
                  },
                ]}
              >
                <AppOrderTimeline
                  title={`Transaction ${selectedRow?.identifier}`}
                  list={(selectedRow?.history || []).map((entry, index) => ({
                    id: index,
                    title: entry.status,
                    color: PayoutStatusToColorMap[entry.status],
                    time: entry.date,
                  }))}
                />
                <ReactJson src={getFormattedSelectedRow()} />
              </TabContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedRow(null)}>Close</Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <ConfirmationDialog
        title={`Confirm deletion of Payout: ${deletedTransaction?.identifier}`}
        open={Boolean(deletedTransaction)}
        onCancel={() => setDeleteTransaction(null)}
        onConfirm={async () => {
          const res = await deletePayout({
            identifier: deletedTransaction?.identifier,
            appId: id,
            page,
            view,
          });
          if (res) {
            setDeleteTransaction(null);
            setIdentifier("");
          }
        }}
      />
    </Grid>
  );
}

import React, { useEffect } from "react";
import withAuth from "../hoc/withAuth";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../components/ui/Message";
import { useApplication } from "../store/AppContext";

function VerifyProfilePage() {
  const { status } = useParams();
  const navigate = useNavigate();
  const { user } = useApplication();
  const getMessageProps = () => {
    if (!status) {
      return {};
    }

    if (status === "success") {
      return { message: "Your account has been verified", severity: "success" };
    }

    return { message: "Verification Failed", severity: "error" };
  };

  useEffect(() => {
    if (user) {
      if (user?.verified && status === "error") {
        navigate("/404");
        return;
      }
      if (!user?.verified && status === "success") {
        navigate("/404");
        return;
      }
    }
    if (["success", "error"].indexOf(status) === -1) {
      navigate("/404");
      return;
    }
  }, [status, navigate, user]);

  return (
    <Grid container spacing={3}>
      <Grid item md={3} xs={12}></Grid>

      <Grid item md={6} xs={12} sx={{ p: 2 }}>
        <img
          src={`/assets/${status}.png`}
          alt="verification"
          style={{ maxWidth: "400px", display: "block", margin: "auto" }}
        />
        <Message sx={{ my: 2 }} {...getMessageProps()} />
      </Grid>
    </Grid>
  );
}

export default withAuth(VerifyProfilePage);

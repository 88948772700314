import { Helmet } from "react-helmet-async";
import { Grid, Container } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import { useCallback, useEffect, useMemo } from "react";
import DashboardProviderInfo from "../components/dashboard/DashboardProviderInfo";
import TransactionsTimeSeries from "../components/dashboard/TransactionsTimeSeries";
import {
  PaymentStatuses,
  PayoutStatuses,
} from "../components/dashboard/constants";
import TabContainer from "../components/tabs/TabContainer";
import { Money, Payment } from "@mui/icons-material";
import Message from "../components/ui/Message";

function DashboardAppPage() {
  const {
    getApplications,
    total,
    getDashboardData,
    metaData,
    dashboardData,
    dashboardPayoutsData,
  } = useApplication();
  useEffect(() => {
    getApplications();
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalTransactions = useMemo(() => {
    return (dashboardData?.generic || []).reduce(
      (acc, { count }) => acc + (+count || 0),
      0
    );
  }, [dashboardData]);

  const totalPayouts = useMemo(() => {
    return (dashboardPayoutsData?.generic || []).reduce(
      (acc, { count }) => acc + (+count || 0),
      0
    );
  }, [dashboardPayoutsData]);

  const labelFN = useCallback(
    (entry) => new Date(entry.date).toDateString(),
    []
  );
  const valueFN = useCallback((entry) => entry.count || 0, []);

  const timeSeriesByProviderFilter = useCallback(
    (provider) => (entry) => entry.provider === provider,
    []
  );

  const timeSeriesByProviderAndStatusFilter = useCallback(
    (provider, status) => (entry) =>
      entry.provider === provider && entry.status === status,
    []
  );

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <AppWidgetSummary
                  title="Applications"
                  total={total}
                  icon={"ant-design:appstore"}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <AppWidgetSummary
                  color="success"
                  title="Transactions"
                  total={totalTransactions}
                  icon={"ant-design:credit-card"}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <AppWidgetSummary
                  color="warning"
                  title="Payouts"
                  total={totalPayouts}
                  icon={"ant-design:credit-card"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <TabContainer
              sx={{ p: 2 }}
              tabs={[
                {
                  name: "Transactions",
                  label: "Transactions",
                  icon: <Payment />,
                },
                {
                  name: "Payouts",
                  label: "Payouts",
                  icon: <Money />,
                },
              ]}
            >
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TransactionsTimeSeries
                    title="Transactions History"
                    data={dashboardData?.timeseries || []}
                    labelFN={labelFN}
                    valueFN={valueFN}
                  />
                </Grid>

                {(metaData?.providers || []).map((provider) => (
                  <Grid item md={6} xs={12} key={provider}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <TransactionsTimeSeries
                          title={`Transaction History (${provider})`}
                          data={dashboardData?.timeseriesByProvider || []}
                          filterFN={timeSeriesByProviderFilter(provider)}
                          labelFN={labelFN}
                          valueFN={valueFN}
                        />
                      </Grid>
                      {PaymentStatuses.map((status) => (
                        <Grid item md={12} xs={12} key={status}>
                          <TransactionsTimeSeries
                            title={`${status} Transactions (${provider})`}
                            data={
                              dashboardData?.timeseriesByProviderAndStatus || []
                            }
                            filterFN={timeSeriesByProviderAndStatusFilter(
                              provider,
                              status
                            )}
                            labelFN={labelFN}
                            valueFN={valueFN}
                          />
                        </Grid>
                      ))}
                      <Grid item md={12} xs={12}>
                        <DashboardProviderInfo
                          provider={provider}
                          data={dashboardData?.generic || []}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TransactionsTimeSeries
                    tooltipTitle="Payouts"
                    title="Payouts History"
                    data={dashboardPayoutsData?.timeseries || []}
                    labelFN={labelFN}
                    valueFN={valueFN}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Message
                    message="Stripe Payouts are not supported at the moment"
                    severity="info"
                  />
                </Grid>
                {(metaData?.providers || []).map((provider) => (
                  <Grid item md={6} xs={12} key={provider}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <TransactionsTimeSeries
                          tooltipTitle="Payouts"
                          title={`Payouts History (${provider})`}
                          data={
                            dashboardPayoutsData?.timeseriesByProvider || []
                          }
                          filterFN={timeSeriesByProviderFilter(provider)}
                          labelFN={labelFN}
                          valueFN={valueFN}
                        />
                      </Grid>
                      {PayoutStatuses.map((status) => (
                        <Grid item md={12} xs={12} key={status}>
                          <TransactionsTimeSeries
                            tooltipTitle="Payouts"
                            title={`${status} Payouts (${provider})`}
                            data={
                              dashboardPayoutsData?.timeseriesByProviderAndStatus ||
                              []
                            }
                            filterFN={timeSeriesByProviderAndStatusFilter(
                              provider,
                              status
                            )}
                            labelFN={labelFN}
                            valueFN={valueFN}
                          />
                        </Grid>
                      ))}
                      <Grid item md={12} xs={12}>
                        <DashboardProviderInfo
                          type="Payout"
                          provider={provider}
                          data={dashboardPayoutsData?.generic || []}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </TabContainer>
          </Grid>
          <Grid item md={12} xs={12}></Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(DashboardAppPage);

import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Title({ divider, sx, children, onClick, ...rest }) {
  return (
    <>
      <Typography
        {...rest}
        component="div"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          ...sx,
        }}
        onClick={onClick}
      >
        {children}
      </Typography>
      {divider && <Divider sx={{ mb: 2 }} />}
    </>
  );
}

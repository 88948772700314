import DeleteApiKeyActionButton from "./DeleteApiKeyActionButton";

export const API_KEYS_TABLE_COLUMNS = [
  { name: "api_key", label: "Api Key" },
  {
    name: "createdAt",
    label: "Created At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "updatedAt",
    label: "Updated At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: DeleteApiKeyActionButton,
  },
];

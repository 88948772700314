import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Forbidden from "../pages/Forbidden";
import { useApplication } from "../store/AppContext";

export default function withRole(Component) {
  return (props) => {
    const { isAuthenticated, user } = useApplication();

    const navigate = useNavigate();
    useEffect(() => {
      if (!localStorage.getItem("token")) {
        navigate("/login");
      }
    }, [navigate]);

    if (!isAuthenticated || !localStorage.getItem("token")) {
      return null;
    }

    if (user?.role !== "ADMIN") {
      return <Forbidden />;
    }

    return <Component {...props} />;
  };
}

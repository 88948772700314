import { Payment } from "@mui/icons-material";
import { Button, Dialog, Divider, Grid } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useApplication } from "../store/AppContext";

const StripeCheckoutForm = ({
  amount,
  clientSecret,
  setLoading,
  isGlobalCheckout,
  metaData,
  appTitle,
  provider,
  currency,
  orderID,
  loading,
  ...rest
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [wasSuccessful, setSuccessful] = useState(false);
  const { createNotification } = useApplication();
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // elements.submit();
      if (!stripe || !elements) {
        return;
      }

      setLoading(true);
      setSuccessful(true);
      // let redirect = "if_required";
      // if (isGlobalCheckout) {
      //   redirect = "always";
      // }
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        // elements,
        // clientSecret,
        // redirect,
        // confirmParams: {
        //   return_url: `${
        //     metaData?.success_url || "/"
        //   }?appTitle=${appTitle}&provider=${
        //     provider?.name
        //   }&amount=${amount}${currency}&orderId=${orderID}`,
        // },
      });

      if (result.error) {
        if (isGlobalCheckout) {
          if (["validation_error"].indexOf(result?.error?.type) === -1) {
            window.location.replace(
              `${metaData?.error_url}?appTitle=${appTitle}&provider=${provider?.name}&amount=${amount}${currency}&orderId=${orderID}`
            );
          } else {
            setSuccessful(false);
          }
        }
        createNotification("Payment Failed", "error");
        setSuccessful(false);
      } else {
        createNotification("Payment Intent Sent!", "success");
        window.location.replace(
          `${metaData?.success_url}?appTitle=${appTitle}&provider=${provider?.name}&amount=${amount}${currency}&orderId=${orderID}`
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      createNotification("Something went wrong", "error");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <CardElement />
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item md={12}>
                {!wasSuccessful ? (
                  <Button
                    type="submit"
                    startIcon={<Payment />}
                    variant="contained"
                    disabled={!stripe || loading}
                  >
                    Confirm Payment
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default StripeCheckoutForm;

import { Edit, Security } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Container, Grid } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import ChangePasswordForm from "../components/profile/ChangePasswordForm";
import ProfileForm from "../components/profile/ProfileForm";
import Title from "../components/ui/Title";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";

function Profile() {
  const { user } = useApplication();

  return (
    <>
      <Helmet>
        <title> Profile </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            {user ? (
              <Card>
                <CardHeader
                  title={
                    <Title>
                      <Edit sx={{ mr: 2 }} /> Edit Profile
                    </Title>
                  }
                />
                <CardContent>
                  <ProfileForm />
                </CardContent>
              </Card>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <Card>
              <CardHeader
                title={
                  <Title>
                    <Security sx={{ mr: 2 }} /> Change Password
                  </Title>
                }
              />
              <CardContent>
                <ChangePasswordForm />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(Profile);

import { Card, CardHeader, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useChart } from "../chart";

export default function TransactionsTimeSeries({
  data = [],
  filterFN,
  labelFN,
  valueFN,
  title = "",
  subtitle = "",
  height = 300,
  tooltipTitle = "Transactions",
}) {
  const { labels, series } = useMemo(() => {
    const initialValues = { labels: [], series: [] };
    if (!Array.isArray(data)) return initialValues;
    let formattedData = data;
    if (filterFN) {
      formattedData = data.filter(filterFN);
    }

    return formattedData.reduce(
      (acc, entry) => {
        acc.labels.push(labelFN(entry, formattedData));
        acc.series[0].data.push(valueFN(entry, formattedData));
        return acc;
      },
      { labels: [], series: [{ name: tooltipTitle, data: [] }] }
    );
  }, [data, filterFN, labelFN, valueFN, tooltipTitle]);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: series.map((_) => "solid") },
    labels: labels || [],
    // xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          return y;
        },
      },
    },
  });

  //   if (!series?.[0]?.data?.length) {
  //     return null;
  //   }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Card>
          <CardHeader title={title} subheader={subtitle} />

          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart
              type="line"
              series={series}
              options={chartOptions}
              height={height}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

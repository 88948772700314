import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import Title from "./Title";

export default function Loader({ loading, title }) {
  return (
    <Backdrop open={loading} sx={{ zIndex: 20000 }}>
      <CircularProgress color="primary" />
      <Title>{title}</Title>
    </Backdrop>
  );
}

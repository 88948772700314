import { SaveAlt } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import { useApplication } from "../../store/AppContext";
import Input from "../forms/Input";

export default function ProfileForm() {
  const { user, authLoading, updateProfile } = useApplication();

  const formConfig = useForm({
    name: {
      defaultValue: user?.name || "",
      validators: (value) =>
        ValidationService.validateString({
          value,
          min: 2,
          max: 50,
          noWhiteSpace: true,
        }),
    },
    last_name: {
      defaultValue: user?.last_name || "",
      validators: (value) =>
        ValidationService.validateString({
          value,
          min: 2,
          max: 50,
          noWhiteSpace: true,
        }),
    },
  });

  const handleSubmit = async () => {
    const formValues = formConfig.getFormValues();
    const res = await updateProfile(formValues);
    if (res) {
      formConfig.resetForm({
        name: res?.data?.name,
        last_name: res?.data?.last_name,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Input isTouched={false} value={user?.email} disabled label="Email" />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          {...formConfig.form.name}
          value={formConfig.form.name.value}
          onChange={formConfig.handleChange}
          label="Name"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Input
          {...formConfig.form.last_name}
          value={formConfig.form.last_name.value}
          onChange={formConfig.handleChange}
          label="Last Name"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Button
          onClick={handleSubmit}
          disabled={!formConfig.isValid || authLoading || !formConfig.isTouched}
          variant="contained"
          fullWidth
          startIcon={<SaveAlt />}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

import {
  AppsOutlined,
  CalendarMonth,
  FlakyOutlined,
  Info,
  Label,
} from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import { useApplication } from "../../store/AppContext";
import Title from "../ui/Title";

export default function AppInfo() {
  const { application } = useApplication();

  if (!application) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ height: "100%" }}>
      <Grid item md={12} xs={12} sx={{ height: "100%" }}>
        <Paper sx={{ p: 2, height: "100%" }}>
          <List
            sx={{ height: "100%" }}
            subheader={
              <Title>
                <Info sx={{ mr: 2 }} /> Application Info
              </Title>
            }
          >
            <ListItem>
              <ListItemAvatar>
                <AppsOutlined />
              </ListItemAvatar>
              <ListItemText
                secondary={"Application Name"}
                primary={application?.name}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Label />
              </ListItemAvatar>
              <ListItemText
                secondary={"Application Title"}
                primary={application?.title}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <FlakyOutlined />
              </ListItemAvatar>
              <ListItemText
                secondary={"Status"}
                primary={application?.status}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <CalendarMonth />
              </ListItemAvatar>
              <ListItemText
                secondary={"Created At"}
                primary={new Date(application?.createdAt)?.toDateString()}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <CalendarMonth />
              </ListItemAvatar>
              <ListItemText
                secondary={"Updated At"}
                primary={new Date(application?.updatedAt)?.toDateString()}
              ></ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
}

import { Chip } from "@mui/material";
import PayoutMenuActions from "./PayoutMenuActions";

export const PAYOUTS_TABLE_COLUMNS = [
  {
    name: "identifier",
    label: "Internal ID",
    disabledSorting: true,
    renderComponent: ({ row }) => <Chip label={row?.identifier} />,
  },
  {
    name: "payoutId",
    label: "Payout ID",
    disabledSorting: true,
    renderComponent: ({ row }) => <Chip label={row?.payoutId} />,
  },

  {
    name: "application",
    label: "Application Name",
    renderComponent: ({ row }) => <Chip label={row?.application?.name} />,
  },
  {
    name: "status",
    label: "Status",
    renderComponent: ({ value }) => <Chip label={value} />,
  },
  {
    name: "provider",
    label: "Provider",
    renderComponent: ({ row }) => <Chip label={row?.provider?.name} />,
  },
  {
    name: "createdAt",
    label: "Created At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "updatedAt",
    label: "Updated At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "amount",
    label: "Amount",
    renderComponent: ({ row }) => (
      <Chip label={`${row?.amount || 0} ${row?.currency || "UNKNOWN"}`} />
    ),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: PayoutMenuActions,
  },
];

export const PAYOUT_STATUSES = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  ERROR: "ERROR",
  UNCLAIMED: "UNCLAIMED",
  CANCELED: "CANCELED",
  SENT: "SENT",
  CREATED: "CREATED",
};

export const PayoutStatusToColorMap = {
  COMPLETED: "success",
  ERROR: "error",
  PENDING: "warning",
  CANCELED: "error",
  SENT: "info",
  UNCLAIMED: "warning",
  CREATED: "info",
};

import { Card, CardContent, Container, Grid } from "@mui/material";
import React from "react";
import Title from "../components/ui/Title";
import withCheckoutLayout from "../hoc/withCheckoutLayout";

function PaymentCompleted({ children }) {
  return (
    <Container maxWidth="md">
      <Grid container spacing={3} alignItems="center" sx={{ height: "100vh" }}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardContent sx={{ dispaly: "flex", justifyContent: "center" }}>
              <Title sx={{ justifyContent: "space-evenly" }}>
                <img
                  style={{ width: "200px", height: "200px" }}
                  src={"/assets/success.png"}
                  alt="success"
                />
                <h4>Payment Completed</h4>
              </Title>
              {children}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withCheckoutLayout(PaymentCompleted);

import { Helmet } from "react-helmet-async";
import { Grid, Container, Paper } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import ApplicationForm from "../components/applications/ApplicationForm";
import { useApplication } from "../store/AppContext";

function NewApplication() {
  const { metaData, createNewApplication } = useApplication();

  return (
    <Paper sx={{ p: 2 }}>
      <Helmet>
        <title> New Application </title>
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title="New Application"
              icon={"ant-design:appstore"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {metaData && <ApplicationForm onSubmit={createNewApplication} />}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

export default withAuth(NewApplication);

/* eslint-disable react/prop-types */
import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
} from "@mui/material";

export default function TableHeader({
  columns = [],
  order,
  orderBy,
  onSort,
  rowCount,
  onSelectAllClick,
  numSelected,
  selectionType = "MULTIPLE",
  disableSelection,
}) {
  return (
    <TableHead>
      <TableRow>
        {!disableSelection && (
          <TableCell padding="checkbox">
            {selectionType === "MULTIPLE" && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            )}
          </TableCell>
        )}

        {columns.map(
          (
            {
              name,
              label,
              align = "left",
              disablePadding = false,
              disableSorting,
            },
            idx
          ) => (
            <TableCell
              key={idx}
              align={align}
              padding={disablePadding ? "none" : "normal"}
              sortDirection={orderBy === name ? order : false}
              onClick={() => (!disableSorting ? onSort(name) : null)}
            >
              {!disableSorting ? (
                <TableSortLabel
                  active={orderBy === name}
                  direction={order?.toLowerCase()}
                  onClick={() => (!disableSorting ? onSort(name) : null)}
                >
                  {label}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

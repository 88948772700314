import { Helmet } from "react-helmet-async";
import { Grid, Container, Button } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TransactionsListView from "../components/transactions/TransactionsListView";
import AppInfo from "../components/applications/AppInfo";
import DashboardProviderInfo from "../components/dashboard/DashboardProviderInfo";
import TransactionsTimeSeries from "../components/dashboard/TransactionsTimeSeries";
import { PaymentStatuses } from "../components/dashboard/constants";

function TransactionsPage() {
  const {
    getTransactions,
    total,
    getApplication,
    getAppMetaData,
    setApplication,
    setAppMetaData,
    metaData,
    appMetaData,
  } = useApplication();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getApplication(id).then(() => {
      getTransactions(id);
      getAppMetaData(id);
    });
    return () => {
      setApplication(null);
      setAppMetaData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelFN = useCallback(
    (entry) => new Date(entry.date).toDateString(),
    []
  );
  const valueFN = useCallback((entry) => entry.count || 0, []);

  const timeSeriesByProviderFilter = useCallback(
    (provider) => (entry) => entry.provider === provider,
    []
  );

  const timeSeriesByProviderAndStatusFilter = useCallback(
    (provider, status) => (entry) =>
      entry.provider === provider && entry.status === status,
    []
  );

  return (
    <>
      <Helmet>
        <title> Transactions </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title="Transactions"
              total={total}
              icon={"ant-design:credit-card"}
            >
              <Button
                variant="contained"
                onClick={() => navigate(`/manage-application/${id}`)}
              >
                Manage Application
              </Button>
            </AppWidgetSummary>
          </Grid>
          <Grid item md={3} xs={12}>
            <AppInfo />
          </Grid>
          <Grid item md={9} xs={12}>
            <TransactionsListView />
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TransactionsTimeSeries
                  title="Transactions History"
                  data={appMetaData?.timeseries || []}
                  labelFN={labelFN}
                  valueFN={valueFN}
                />
              </Grid>
              {(metaData?.providers || []).map((provider) => (
                <Grid item md={6} xs={12} key={provider}>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <TransactionsTimeSeries
                        title={`Transaction History (${provider})`}
                        data={appMetaData?.timeseriesByProvider || []}
                        filterFN={timeSeriesByProviderFilter(provider)}
                        labelFN={labelFN}
                        valueFN={valueFN}
                      />
                    </Grid>
                    {PaymentStatuses.map((status) => (
                      <Grid item md={12} xs={12} key={status}>
                        <TransactionsTimeSeries
                          title={`${status} Transactions (${provider})`}
                          data={
                            appMetaData?.timeseriesByProviderAndStatus || []
                          }
                          filterFN={timeSeriesByProviderAndStatusFilter(
                            provider,
                            status
                          )}
                          labelFN={labelFN}
                          valueFN={valueFN}
                        />
                      </Grid>
                    ))}
                    <Grid item md={12} xs={12}>
                      <DashboardProviderInfo
                        provider={provider}
                        data={appMetaData?.generic || []}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(TransactionsPage);

import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";

import { useApplication } from "../../../store/AppContext";
import { useNavigate } from "react-router-dom";
import { ExitToApp, Info, Mail, Person } from "@mui/icons-material";

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { logout, user } = useApplication();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Info sx={{ mr: 2 }} /> {user?.name || "-"} {user?.last_name || "-"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Mail sx={{ mr: 2 }} /> {user?.email}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
          >
            <Person sx={{ mr: 2 }} />
            Profile
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
          sx={{ m: 1 }}
        >
          <ExitToApp sx={{ mr: 2 }} /> Logout
        </MenuItem>
      </Popover>
    </>
  );
}

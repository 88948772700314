import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import NewApplication from "./pages/NewApplication";
import ManageApplication from "./pages/ManageApplication";
import TestPayments from "./pages/TestPayments";
import TransactionsPage from "./pages/TransactionsPage";
import ApplicationsPage from "./pages/Applications";
import ApiKeysPage from "./pages/ApiKeysPage";
import RegisterPage from "./pages/RegisterPage";
import ManageUsers from "./pages/ManageUsers";
import Profile from "./pages/Profile";
import Checkout from "./pages/Checkout";
import PaymentCompleted from "./pages/PaymentCompleted";
import PaymentFailed from "./pages/PaymentFailed";
import Documentation from "./pages/Documentation";
import PayoutsPage from "./pages/PayoutsPage";
import VerifyProfilePage from "./pages/VerifyProfilePage";
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "applications", element: <ApplicationsPage /> },
        { path: "new-application", element: <NewApplication /> },
        { path: "manage-application/:id", element: <ManageApplication /> },
        {
          path: "manage-application/:id/transactions",
          element: <TransactionsPage />,
        },
        {
          path: "manage-application/:id/payouts",
          element: <PayoutsPage />,
        },
        { path: "manage-application/:id/test", element: <TestPayments /> },
        { path: "manage-api-keys", element: <ApiKeysPage /> },
        { path: "profile", element: <Profile /> },
        { path: "profile/:status", element: <VerifyProfilePage /> },
        { path: "users", element: <ManageUsers /> },
      ],
    },
    { path: "checkout/:key", element: <Checkout /> },
    { path: "checkout-success", element: <PaymentCompleted /> },
    { path: "checkout-error", element: <PaymentFailed /> },

    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "register",
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

import { useState } from "react";
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify";
import useForm from "../../../hooks/useForm";
import ValidationService from "../../../services/ValidationService";
import { useApplication } from "../../../store/AppContext";

export default function RegisterForm() {
  const { authLoading, register } = useApplication();
  const [showPassword, setShowPassword] = useState(false);
  const formConfig = useForm({
    name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
    last_name: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          min: 2,
          max: 50,
          noWhiteSpace: true,
          value,
        }),
    },
    email: {
      defaultValue: "",
      validators: ValidationService.validateEmail,
    },
    password: {
      defaultValue: "",
      validators: (value) =>
        ValidationService.validateString({
          min: 8,
          max: 16,
          noWhiteSpace: true,
          value,
        }),
    },
  });

  const handleRegister = async () => {
    const formVaues = formConfig.getFormValues();
    const res = await register(formVaues);
    if (res) {
      resetForm();
    }
  };

  const { handleChange, form, resetForm, isValid } = formConfig;

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="name"
          label="Name"
          onChange={handleChange}
          value={form.name.value}
        />

        <TextField
          name="last_name"
          label="Last Name"
          onChange={handleChange}
          value={form.last_name.value}
        />
        <TextField
          name="email"
          label="Email address"
          onChange={handleChange}
          value={form.email.value}
        />

        <TextField
          onChange={handleChange}
          value={form.password.value}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleRegister}
        disabled={!isValid || authLoading}
      >
        Register
      </LoadingButton>
    </>
  );
}

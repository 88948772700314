import { useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  TablePagination,
  Backdrop,
  Checkbox,
} from "@mui/material";
import TableToolbar from "../main/TableToolbar";
import TableHeader from "../main/TableHeader";

export default function RestTable({
  rows = [],
  columns,
  page,
  view,
  allowedViews = [5, 10, 20, 50, 100],
  onPageChange,
  onViewChange,
  count,
  isLoading,
  disableSelection = false,
  selectionType = "MULTIPLE",
  onSelect,
  onSelectAll,
  selectionFn,
  actions,
  id,
  title,
  defaultSortingColumn = null,
  defaultSortingId,
  defaultOrderBy = "ASC",
  isRecursive,
  parentData,
  menuIcon,
  toolbarMenuStyle,
  renderToolbarChildren,
  onSortChange,
  columnProps = {},
  fallbackComponent,
  onRowClick,
}) {
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState(defaultOrderBy);
  const [orderBy, setOrderBy] = useState(defaultSortingId);
  const headCells = useMemo(
    () => (columns || []).filter((col) => !col.isHidden),
    [columns]
  );

  const renderCellValue = (
    row,
    id,
    rows,
    formatter,
    RenderComponent,
    path,
    children
  ) => {
    if (RenderComponent) {
      return (
        <RenderComponent
          {...{
            value: row?.[id],
            row,
            rows,
            selected,
            formattedValue: formatter
              ? formatter(row?.[id], row, rows)
              : row?.[id],
            ...columnProps?.[id],
          }}
        />
      );
    }

    if (formatter) {
      return formatter(row?.[id], row, rows);
    }

    return row?.[id];
  };

  const handleChangePage = async (_, newPage) => {
    if (onPageChange) {
      onPageChange(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = async (e) => {
    const newView = +e.target.value;
    if (onViewChange) {
      onViewChange(newView);
    }
  };

  const isItemSelected = (row) => {
    if (selectionFn) return selectionFn(row, selected);
    if (id) return selected.some((x) => x[id] === row[id]);
    return false;
  };

  const handleSelect = (value, row) => {
    if (selectionType === "SINGLE") {
      setSelected((prev) => (!value ? [] : [row]));
      if (onSelect) {
        onSelect([row], row);
      }
    } else {
      setSelected((prev) => {
        const isSelected = isItemSelected(row, prev);
        const newSelected = !isSelected
          ? [...prev, row]
          : prev.filter((x) => x[id] !== row[id]);
        if (onSelect) onSelect(newSelected, row);
        return newSelected;
      });
    }
  };

  const handleSelectAll = async (e) => {
    if (!e.target.checked) {
      setSelected([]);
      if (onSelect) onSelect([], undefined);
      return;
    }
    if (count === rows?.length) {
      setSelected((prev) => [...rows]);
      if (onSelect) {
        onSelect(rows, undefined);
      }
      return;
    }

    if (onSelectAll) {
      const items = await onSelectAll();
      if (items) {
        setSelected((prev) => [...items]);
      }
    }
  };

  return (
    <>
      <Box>
        <TableToolbar
          renderToolbarChildren={renderToolbarChildren}
          menuIcon={menuIcon}
          title={title}
          selected={selected}
          actions={actions}
          setSelected={setSelected}
          onSelect={onSelect}
          toolbarMenuStyle={toolbarMenuStyle}
        />
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          {isLoading && (
            <div style={{ position: "absolute", left: "50%", top: "20%" }}>
              <Backdrop open={isLoading} sx={{ zIndex: 1000 }}>
                <CircularProgress />
              </Backdrop>
            </div>
          )}
          <Table
            aria-labelledby="tableTitle"
            sx={{
              "& .MuiTableCell-root:first-child": {
                pl: 2,
              },
              "& .MuiTableCell-root:last-child": {
                pr: 3,
              },
            }}
          >
            <TableHeader
              disableSelection={disableSelection}
              selectionType={!disableSelection && selectionType}
              columns={headCells}
              numSelected={selected?.length || 0}
              rowCount={count}
              onSelectAllClick={handleSelectAll}
              order={order}
              onSort={async (col) => {
                if (!onSortChange) {
                  return;
                }
                let newOrderBy = col;
                let newOrder = "ASC";
                const swapDirectionMap = {
                  ASC: "DESC",
                  DESC: "ASC",
                };
                const wasAlreadySelected = col === orderBy;
                if (wasAlreadySelected) {
                  if (order === defaultOrderBy) {
                    newOrder = swapDirectionMap[order];
                  } else {
                    newOrder = undefined;
                  }
                } else {
                  newOrder = defaultOrderBy;
                }

                if (typeof newOrder === "undefined") {
                  newOrderBy = undefined;
                }

                if (!newOrderBy) {
                  onSortChange();
                } else onSortChange({ [newOrderBy]: newOrder });

                setOrder(newOrder);
                setOrderBy(newOrderBy);
              }}
            />
            <TableBody>
              {(Array.isArray(rows) ? rows : []).map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      ...(onRowClick && { cursor: "pointer" }),
                    }}
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    onClick={(e) => {
                      if (onRowClick) {
                        onRowClick(row, index);
                      }
                    }}
                    // selected={isItemSelected}
                  >
                    {!disableSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected(row)}
                          onChange={(e) => handleSelect(e.target.checked, row)}
                        />
                      </TableCell>
                    )}
                    {headCells.map((col, idx) => (
                      <TableCell
                        key={idx}
                        component={idx === 0 ? "th" : "td"}
                        // id={labelId}
                        onClick={() =>
                          col?.onClick ? col?.onClick(row, col.name) : null
                        }
                        scope={idx === 0 ? "row" : undefined}
                        align={col.align}
                      >
                        {renderCellValue(
                          row,
                          col.name,
                          rows,
                          col.formatter,
                          col.renderComponent,
                          col.path,
                          col.children
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={allowedViews}
          component="div"
          count={!isNaN(count) ? +count : 0}
          rowsPerPage={view}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

import { Helmet } from "react-helmet-async";
import { Grid, Container } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import { useEffect } from "react";
import ApiKeysListView from "../components/api-keys/ApiKeysListView";

function ApiKeysPage() {
  const { getApiKeys, total } = useApplication();

  useEffect(() => {
    getApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Api Keys </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title="Api Keys"
              total={total}
              icon={"ant-design:key"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <ApiKeysListView />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(ApiKeysPage);

import { Chip } from "@mui/material";
import UserMenuActions from "./UserMenuActions";

export const USERS_TABLE_COLUMNS = [
  { name: "_id", label: "ID", disabledSorting: true },
  { name: "email", label: "Email" },
  {
    name: "name",
    label: "Full Name",
    renderComponent: ({ value, row }) =>
      `${row?.name || "-"} ${row?.last_name || "-"}`,
  },
  {
    name: "role",
    label: "Role",
    renderComponent: ({ value }) => <Chip label={value} />,
  },
  {
    name: "verified",
    label: "Verified",
    renderComponent: ({ value }) => (
      <Chip label={value ? "Verified" : "Not Verified"} />
    ),
  },
  {
    name: "approved",
    label: "Approved",
    renderComponent: ({ value }) => (
      <Chip label={value ? "Approved" : "Not Approved"} />
    ),
  },
  {
    name: "createdAt",
    label: "Created At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "updatedAt",
    label: "Updated At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: UserMenuActions,
  },
];

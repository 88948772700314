// component
import {
  AppsOutlined,
  IntegrationInstructions,
  Key,
  Person,
  SupervisedUserCircle,
} from "@mui/icons-material";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/app",
    icon: icon("ic_analytics"),
    roles: ["ADMIN", "TENANT"],
  },
  {
    title: "applications",
    path: "/applications",
    icon: <AppsOutlined />,
    roles: ["ADMIN", "TENANT"],
  },
  {
    title: "api keys",
    path: "/manage-api-keys",
    icon: <Key />,
    roles: ["ADMIN", "TENANT"],
  },
  {
    title: "users",
    path: "/users",
    icon: <SupervisedUserCircle />,
    roles: ["ADMIN"],
  },
  {
    title: "profile",
    path: "/profile",
    icon: <Person />,
    roles: ["ADMIN", "TENANT"],
  },
  // {
  //   title: "documentation",
  //   path: "/documentation",
  //   icon: <IntegrationInstructions />,
  //   roles: ["ADMIN", "TENANT"],
  // },
];

export default navConfig;

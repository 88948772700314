/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Typography, Toolbar, Tooltip, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

export default function TableToolbar(props) {
  const {
    selected = [],
    actions = [],
    title,
    setSelected,
    menuIcon,
    toolbarMenuStyle = {},
    onSelect,
    renderToolbarChildren,
  } = props;
  const numSelected = selected.length;

  const shouldRenderIconButtons =
    numSelected > 0 && actions.length > 0 && actions.length < 4;

  const shouldRenderActionMenu = numSelected > 0 && actions?.length > 3;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const disableAction = (action) => {
    if (!action.disabled) return false;
    if (typeof action.disabled === "function") {
      return action.disabled(selected);
    }

    return !!action.disabled;
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      {title ? (
        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ flex: 1 }}
        >
          {title} {numSelected > 0 ? `(${numSelected})` : ""}
        </Typography>
      ) : null}
      <div>
        {shouldRenderActionMenu ? (
          <>
            <IconButton onClick={handleClick}>
              {menuIcon || <MoreVertIcon />}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                  ...toolbarMenuStyle,
                },
              }}
            >
              {actions.map((action, idx) => (
                <MenuItem
                  disabled={disableAction(action)}
                  key={idx}
                  onClick={() =>
                    !disableAction(action)
                      ? action.onClick(
                          selected,
                          (selected) => {
                            setSelected(selected);
                            if (onSelect) {
                              onSelect(selected);
                            }
                          },
                          handleClose
                        )
                      : null
                  }
                >
                  {action.icon || null} {action.title || ""}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : null}
      </div>
      <div>
        {shouldRenderIconButtons
          ? actions.map((action, idx) => {
              return (
                <Tooltip key={idx} title={action.title}>
                  <IconButton
                    color={action.colorIcon || "primary"}
                    onClick={() =>
                      action.onClick(selected, (selected) => {
                        setSelected(selected);
                        if (onSelect) {
                          onSelect(selected);
                        }
                      })
                    }
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              );
            })
          : null}
      </div>
      <div>{renderToolbarChildren?.()}</div>
    </Toolbar>
  );
}

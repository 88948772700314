import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplication } from "../store/AppContext";

export default function AuthWrapper({ children }) {
  const { isAuthenticated } = useApplication();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token || isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate, token]);

  if (token) return null;

  return children;
}

import { Helmet } from "react-helmet-async";
import { Grid, Container } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import withAuth from "../hoc/withAuth";
import { useApplication } from "../store/AppContext";
import { useCallback, useEffect, useMemo } from "react";
import DashboardProviderInfo from "../components/dashboard/DashboardProviderInfo";
import TransactionsTimeSeries from "../components/dashboard/TransactionsTimeSeries";
import { PaymentStatuses } from "../components/dashboard/constants";
import withRole from "../hoc/withRole";
import UsersListView from "../components/users/UsersListView";

function ManageUsersPage() {
  const { getUsers, total } = useApplication();
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Manage Users </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary
              title="Users"
              total={total}
              icon={"ant-design:user"}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <UsersListView />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAuth(withRole(ManageUsersPage));

import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { useApplication } from "../../store/AppContext";
import { ExitToApp } from "@mui/icons-material";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { user, logout } = useApplication();

  if (!user) return null;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data
          .filter((item) => item.roles.indexOf(user?.role) !== -1)
          .map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        <StyledNavItem
          sx={{
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold",
            },
          }}
          onClick={logout}
        >
          <StyledNavItemIcon>
            <ExitToApp />{" "}
          </StyledNavItemIcon>
          <ListItemText disableTypography primary={"Logout"} />
        </StyledNavItem>
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}

import React, { useState } from "react";
import { useApplication } from "../../store/AppContext";
import { Grid } from "@mui/material";
import Message from "../ui/Message";
import { Check } from "@mui/icons-material";
import Title from "../ui/Title";
import Button from "../ui/Button";

export default function VerifyScreen() {
  const { user, createAccountVerification } = useApplication();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  if (!user) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "center", minHeight: "80vh" }}
    >
      <Grid item md={3}></Grid>
      <Grid
        item
        md={6}
        xs={12}
        style={{ position: "relative", minHeight: "400px" }}
        sx={{ p: 2 }}
      >
        <Title>
          <Check color="success" />
          <h4>
            Hello {user?.name} {user?.last_name}
          </h4>
        </Title>
        <Message
          sx={{ my: 4 }}
          message="Please verify your account in order to continue"
          severity="info"
        />
        <img
          alt="verify account"
          src={"/assets/verify-account.jpg"}
          style={{
            width: "100%",
            height: "100%",
            zIndex: 1,
            pointerEvents: "none",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.2,
          }}
        />
        <Button
          size="sm"
          color="primary"
          variant="contained"
          disabled={disabled || loading}
          loading={loading}
          sx={{ display: "block", mx: "auto", zIndex: 10 }}
          onClick={async () => {
            setLoading(true);
            setDisabled(true);
            await createAccountVerification();
            setLoading(false);
            setDisabled(false);
          }}
        >
          Send Verification
        </Button>
      </Grid>
    </Grid>
  );
}

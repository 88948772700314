import { Add } from "@mui/icons-material";
import { Button, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { useApplication } from "../../store/AppContext";
import RestTable from "../tables/rest/RestTable";
import { API_KEYS_TABLE_COLUMNS } from "./constants";
import useForm from "../../hooks/useForm";
import ValidationService from "../../services/ValidationService";
import SimpleDialog from "../dialogs/SimpleDialog";
import Input from "../forms/Input";
import Title from "../ui/Title";

export default function ApiKeysListView() {
  const {
    page,
    view,
    total,
    api_keys = [],
    getApiKeys,
    createApiKey,
    apiKeysLoading,
  } = useApplication();

  const [open, setOpen] = useState(false);

  const formConfig = useForm({
    api_key: {
      defaultValue: "",
      formatter: (value) => (typeof value === "string" ? value.trim() : value),
      validators: [
        (value) =>
          ValidationService.validateString({ value, min: 6, max: 150 }),
      ],
    },
  });

  const { form, handleChange, resetForm } = formConfig;

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <RestTable
            title={"Api Keys"}
            rows={api_keys}
            columns={API_KEYS_TABLE_COLUMNS}
            onPageChange={async (page) => {
              await getApiKeys(page, view);
            }}
            onViewChange={async (view) => {
              await getApiKeys(page, view);
            }}
            count={total}
            view={view}
            page={page}
            disableSelection
            renderToolbarChildren={() => {
              return (
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Button
                      variant="contained"
                      disabled={apiKeysLoading}
                      startIcon={<Add />}
                      onClick={() => setOpen(true)}
                      sx={{ mr: 2 }}
                    >
                      New Api Key
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Paper>
      </Grid>
      <SimpleDialog
        title={
          <Title>
            <Add sx={{ mr: 2 }} /> Add New Api Key
          </Title>
        }
        open={open}
        onCancel={() => {
          resetForm();
          setOpen(false);
        }}
        onConfirm={async () => {
          const { api_key } = formConfig.getFormValues();
          const res = await createApiKey(api_key);
          if (res) {
            resetForm();
            setOpen(false);
          }
        }}
        disableBackdropClick={apiKeysLoading}
        disableConfirm={
          !formConfig.isValid || !formConfig.isTouched || apiKeysLoading
        }
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Input
              {...form.api_key}
              name="api_key"
              value={form.api_key.value}
              label="Api Key"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </SimpleDialog>
    </Grid>
  );
}

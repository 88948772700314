import { Chip } from "@mui/material";
import TransactionMenuActions from "./TransactionActions";

export const TRANSACTIONS_TABLE_COLUMNS = [
  { name: "identifier", label: "Transaction ID", disabledSorting: true },
  {
    name: "application",
    label: "Application Name",
    renderComponent: ({ row }) => <Chip label={row?.application?.name} />,
  },
  {
    name: "status",
    label: "Status",
    renderComponent: ({ value }) => <Chip label={value} />,
  },
  {
    name: "provider",
    label: "Provider",
    renderComponent: ({ row }) => <Chip label={row?.provider?.name} />,
  },
  {
    name: "createdAt",
    label: "Created At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "updatedAt",
    label: "Updated At",
    renderComponent: ({ value }) => new Date(value).toDateString(),
  },
  {
    name: "amount",
    label: "Amount",
    renderComponent: ({ row }) => (
      <Chip label={`${row?.data?.amount} ${row?.data?.currency}`} />
    ),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: TransactionMenuActions,
  },
];

export const TransactionStatusToColorMap = {
  COMPLETED: "success",
  ERROR: "error",
  PENDING: "primary",
  CANCELED: "error",
  REFUNDED: "warning",
};
